<div class="content">
    <div class="form-container">
        <h1 class="headline" mat-dialog-title>{{"Form.createSubscriptionTitle" | translate}}</h1>

        <form [formGroup]="formData" class="container">
            <mat-label>{{"Form.name" | translate}}</mat-label>
            <div class="inputGroup">
                <input formControlName="firstName" matInput>
                <mat-label class="subTitle">{{"Form.firstName" | translate}}  <span class="required">*</span></mat-label>
            </div>
            <div class="inputGroup">
                <input formControlName="lastName" matInput>
                <mat-label class="lastNameSubTitle">{{"Form.lastName" | translate}}  <span class="required">*</span></mat-label>
            </div>
        </form>

        <form [formGroup]="formData" class="container">
            <div class="inputGroup">
                <mat-label>{{"Form.email" | translate}} <span class="required">*</span></mat-label>
                <input (blur)="checkEmail()" formControlName="email" matInput>
                <input (blur)="checkEmail()" class="confirmEmailInput" formControlName="confirmEmail" matInput placeholder="{{ 'Form.confirmEmail' | translate }}">
                <span *ngIf="formData.get('email')?.invalid && formData.get('confirmEmail')?.invalid && (formData.get('email')?.touched || submitted)" class="errorMessage">{{"Form.enterValidEmail" | translate}}</span>
                <span *ngIf="!this.emailChecked" class="errorMessage">{{"Form.notMatchingEmail" | translate}}</span>
            </div>
        </form>

        <form [formGroup]="formData" class="container">
            <div class="inputGroup">
                <mat-label>{{"Form.organization" | translate}} <span class="required">*</span></mat-label>
                <input formControlName="organization" matInput>
            </div>
        </form>

        <form [formGroup]="formData" class="container">
            <mat-label>{{"Form.address" | translate}}</mat-label>
            <div class="inputGroup">
                <input formControlName="streetAddress" matInput>
                <mat-label class="subTitle">{{"Form.streetAddress" | translate}}</mat-label>
                <input formControlName="city" matInput>
                <mat-label class="subTitle">{{"Form.city" | translate}}</mat-label>
                <input formControlName="state" matInput>
                <mat-label class="subTitle">{{"Form.state" | translate}}</mat-label>
                <input formControlName="postalCode" matInput>
                <mat-label class="subTitle">{{"Form.postal" | translate}}</mat-label>
                <input formControlName="country" matInput>
                <mat-label class="subTitle">{{"Form.country" | translate}}</mat-label>
            </div>
        </form>

        <div class="privacyPolicy container">
            <form [formGroup]="formData">
                <mat-checkbox formControlName="privacyPolicyAccepted">
                    {{"Form.privacyPolicyAcceptance" | translate}}
                </mat-checkbox>
            </form>
            <div>
                <a (click)="openPrivacyPolicy()">{{"Form.privacyPolicyLinkText" | translate}}</a>
            </div>
            <span *ngIf="formData.get('privacyPolicyAccepted')?.invalid && (formData.get('privacyPolicyAccepted')?.touched || submitted)" class="errorMessage">{{"Form.privacyPolicyRequired" | translate}}</span>
        </div>

        <div *ngIf="loading" class="loadingOverlay">
            <div class="loadingContent">
                <mat-spinner diameter="50"></mat-spinner>
                <div class="loadingText">
                    {{"Form.creatingLicense" | translate}}
                </div>
            </div>
        </div>

        <div>
            <button (click)="submitForm()" *ngIf="!loading" class="requestLicenseButton" color="primary" mat-flat-button>
                {{"Form.requestLicense" | translate}}
            </button>
            <div *ngIf="unableToCreateError" class="errorMessage">{{"Form.unableToCreateError" | translate}}</div>
            <div *ngIf="allFieldsFilledError" class="errorMessage">{{"Form.allFieldsFilledError" | translate}}</div>
        </div>
    </div>
</div>
