<app-menubar [header]="caption" [showBackButton]="false"></app-menubar>

<div class="content">
    <button (click)="navigateTo('parts/')" class="iconButton bigButton" color="primary" mat-flat-button>
        <fa-icon [icon]="appIcons.genericAdd" matListItemIcon></fa-icon>
        <span>{{'PartList.newPart' | translate}}</span>
    </button>

    <div>
        <mat-form-field *ngIf="parts.length > 0" appearance="outline" class="search">
            <mat-label>{{'Generic.search' | translate}}</mat-label>
            <input (input)="applySearchFilter()" [(ngModel)]="searchQuery" matInput>
            <button (click)="emptyQuery()" *ngIf="searchQuery" aria-label="Clear" mat-button matSuffix>
                <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
            </button>
        </mat-form-field>
    </div>
    <p *ngIf="parts.length > 0 && filteredParts.length <= 0">{{'PartDesigner.notFound' | translate}} "{{searchQuery}}".</p>

    <mat-action-list>
        <button (click)="loadPart(part)" *ngFor="let part of filteredParts" mat-list-item>
            <fa-icon [icon]="appIcons.jobModeIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{part.id}} - {{partClass.getDisplayName(part)}}</span>
            <fa-icon [icon]="appIcons.clickableListItemIcon" matListItemMeta></fa-icon>
        </button>
    </mat-action-list>
</div>
