import { StatisticRecord } from "./statistic-record";

/**
 * StatisticsCalculator allows to track values and provides methods to get statistics of them.
 */
export class StatisticsCalculator {

    private values: Array<StatisticRecord> = [];

    public add(name: string, value: string): void {
        const valueAsNumber: number = Number(value);

        this.values.push({
            name: name,
            measurementPoint: undefined,
            value: isNaN(valueAsNumber) ? undefined : valueAsNumber
        });

    }

    public addForMeasurementPoint(name: string, measurementPoint: string, value: string): void {
        const valueAsNumber: number = Number(value);
        this.values.push({
            name: name,
            measurementPoint: measurementPoint,
            value: isNaN(valueAsNumber) ? undefined : valueAsNumber
        });

    }

    public getNames(): Array<string> {
        const names: Array<string> = [];
        for (const record of this.values) {
            if (record.name && !names.includes(record.name)) {
                names.push(record.name);
            }
        }
        return names;
    }

    public getMeasurementPoints(): Array<string> {
        const mps: Array<string> = [];
        for (const record of this.values) {
            if (record.measurementPoint && !mps.includes(record.measurementPoint)) {
                mps.push(record.measurementPoint);
            }
        }
        return mps;
    }

    public min(name: string, measurementPoint?: string): number|undefined {
        let min: number|undefined;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }
                if (!min || v.value < min) {
                    min = v.value;
                }
            }
        }
        return min;
    }

    public max(name: string, measurementPoint?: string): number|undefined {
        let max: number|undefined;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }
                if (!max || v.value > max) {
                    max = v.value;
                }
            }

        }
        return max;
    }

    public avg(name: string, measurementPoint?: string): number|undefined {
        let sum: number = 0;
        let count: number = 0;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }
                sum += v.value;
                count++;
            }
        }
        return count > 0 ? sum / count : undefined;
    }
}
