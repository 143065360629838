import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Property } from "../../datamodel/property";

/**
 * PropertyEditor opens a dialog window which supports editing a given property.
 */
@Component({
    selector: "app-property-edit-dialog",
    templateUrl: "./property-edit-dialog.component.html",
    styleUrls: ["./property-edit-dialog.component.scss"]
})
export class PropertyEditDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PropertyEditDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public property: Property
    ) {
        if (!property) {
            this.property = new Property();
            this.property.isNew = true;
            this.property.nameEditable = true;
            this.property.deletable = true;
        } else {
            this.property.oldNameKey = this.property.nameKey;
        }
    }

    public delete(): void {
        this.property.deleted = true;
    }
}
