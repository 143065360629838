import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { ComponentCanDeactivate } from "../../../base/guards/navigate-away.guard";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { PartSelectorDialogComponent } from "../../components/part-selector-dialog/part-selector-dialog.component";
import { JobTemplate } from "../../datamodel/job-template";
import { Part } from "../../datamodel/part";
import { Property } from "../../datamodel/property";
import { DialogService } from "../../services/dialog/dialog.service";
import { JobTemplateService } from "../../services/job-template/job-template.service";
import { PartService } from "../../services/part/part-service";
import { JobTemplateExporter } from "./sharing/job-template-exporter";

/**
 * JobDesigner is used to create/edit jobs.
 */
@Component({
    selector: "app-job-designer",
    templateUrl: "./job-designer.component.html",
    styleUrls: ["./job-designer.component.scss"]
})
export class JobDesignerComponent extends BaseComponent implements ComponentCanDeactivate {
    constructor(
        private route: ActivatedRoute,
        private jobTemplateService: JobTemplateService,
        private partService: PartService,
        private dialog: MatDialog,
        private snackbar: MatSnackBar,
        private navigationService: NavigationService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private jobTemplateExporter: JobTemplateExporter
    ) {
        super();
        this.caption = "JobDesigner.title";
    }

    public displayedColumns: Array<string> = ["selectedParts"];
    public job: JobTemplate = new JobTemplate();
    protected readonly partClass = Part;
    private parameterSubscriber?: Subscription;

    protected componentInit(): void {
        this.route.params.subscribe(async (params: Params) => {
            let jobId: number|undefined;
            if (params["id"]) {
                jobId = Number(params["id"]);
                if (Number.isNaN(jobId)) {
                    jobId = undefined;
                }
            }

            if (jobId) {
                const foundJob: JobTemplate|undefined = await this.jobTemplateService.load(jobId);
                if (foundJob) {
                    this.job = foundJob;
                }
            }
        });
    }

    protected componentDestroy(): void {
        if (this.parameterSubscriber) {
            this.parameterSubscriber.unsubscribe();
        }
    }

    public async willDeactivate(): Promise<void> {
        await this.save();
    }

    public async canDeactivate(): Promise<boolean> {
        return true;
    }

    public getJobProperties(): Array<Property> {
        return this.job.properties;
    }

    public setJobProperties(properties: Array<Property>): void {
        if (this.job?.properties) {
            this.job.properties = [];
            for (const property of properties) {
                if (!property.deleted) {
                    this.job.properties.push(property);
                }
            }
            this.save().then();
        }
    }

    public async save(autosave: boolean = true): Promise<void> {
        await this.jobTemplateService.save(this.job);

        if (!autosave) {
            this.snackbar.open(this.translateService.instant("JobDesigner.saved"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
        }
    }

    public async openPartSelection(): Promise<void> {
        const all: Array<Part> = await this.partService.listParts();

        const dialogRef: MatDialogRef<PartSelectorDialogComponent> = this.dialog.open(PartSelectorDialogComponent, {
            data: {
                parts: all.filter((part: Part) => {
                    for (const existingPart of this.job.parts) {
                        if (existingPart.id == part.id) {
                            return false;
                        }
                    }
                    return true;
                })
            }
        });

        dialogRef.afterClosed().subscribe((result?: Part) => {
            if (result) {
                this.job.parts.push(result);
                this.save().then();
            }
        });
    }

    public removePart(part: Part): void {
        this.job.parts = this.job.parts.filter((p: Part) => p.id != part.id);
        this.save().then();
    }

    public dropPart(event: CdkDragDrop<Array<string>>): void {
        moveItemInArray(this.job.parts, event.previousIndex, event.currentIndex);
        this.save().then();
    }

    public async delete(): Promise<void> {
        if (this.job.id) {
            const result: boolean = await this.dialogService.openDeleteDialog("DeleteDialog.title", "DeleteDialog.description", "", "");

            if (result && this.job?.id) {
                await this.jobTemplateService?.delete(this.job.id);
                await this.navigationService.navigateBack();
                this.snackbar.open(this.translateService.instant("JobDesigner.deleted"), undefined, {
                    duration: this.snackbarDuration,
                    verticalPosition: this.snackbarVerticalPosition
                });
            }

        }
    }

    public async exportTemplate(): Promise<void> {
        if (this.job) {
            await this.jobTemplateExporter.exportJobTemplate(this.job);
        }
    }

}
