import { Component, ViewChild } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { Device, DeviceInfo } from "@capacitor/device";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";

import { AppIcons } from "../../../app-icons";
import { BaseComponent } from "../../../base/components/base/base-component";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { UiHelper } from "../../helpers/ui-helper";
import { InfoRow } from "./info-row";

/**
 * The component to show the BLE status and additionally assist in troubleshooting.
 */
@Component({
    selector: "app-ble-status",
    templateUrl: "./ble-status.component.html",
    styleUrls: ["./ble-status.component.scss"]
})
export class BleStatusComponent extends BaseComponent {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly translateService: TranslateService
    ) {
        super();
    }

    public bluetoothStatus: BluetoothStatus = BluetoothStatus.unknown;

    public initialized: boolean = false;

    private deviceInfo?: DeviceInfo;

    @ViewChild("infoTable")
    private infoTable?: MatTable<InfoRow>;
    public displayedColumns: Array<string> = ["icon", "text"];
    public tableContent: Array<InfoRow> = [];

    @ViewChild("systemTable")
    private systemTable?: MatTable<{ key: string; value: string }>;
    public systemDisplayedColumns: Array<string> = ["key", "value"];
    public systemTableContent: Array<{ key: string; value: string }> = [];

    public get statusIcon(): IconDefinition {
        return UiHelper.getBluetoothStatusIcon(this.bluetoothStatus);
    }

    public get statusText(): string {
        return UiHelper.getBluetoothStatusText(this.bluetoothStatus);
    }

    protected componentInit(): void {
        this.subscribe(this.bluetoothService.allDevicesStatusChanged, this.bluetoothStatusUpdated);
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;

        this.refresh().then();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private async loadDeviceInfo(): Promise<void> {
        try {
            this.deviceInfo = await Device.getInfo();
            this.systemTableContent = [];

            this.addSystemProperty("System.deviceName", this.deviceInfo.name);
            this.addSystemProperty("System.model", this.deviceInfo.model);
            this.addSystemProperty("System.platform", this.deviceInfo.platform);
            this.addSystemProperty("System.operatingSystem", this.deviceInfo.operatingSystem);
            this.addSystemProperty("System.operatingSystemVersion", this.deviceInfo.osVersion);
            this.addSystemProperty("System.iOSVersion", `${this.deviceInfo.iOSVersion}`);
            this.addSystemProperty("System.androidSDKVersion", `${this.deviceInfo.androidSDKVersion}`);
            this.addSystemProperty("System.manufacturer", this.deviceInfo.manufacturer);
            this.addSystemProperty("System.webViewVersion", this.deviceInfo.webViewVersion);
            if (this.deviceInfo.isVirtual) {
                this.addSystemProperty("System.isVirtual", "yes");
            }

            this.systemTable?.renderRows();
        } catch (error) {
            console.warn(error);
        }
    }

    private addSystemProperty(localizationKey: string, value: string|undefined): void {
        if (value && value != "unknown" && value != "undefined") {
            this.systemTableContent.push({ key: this.translateService.instant(localizationKey) as string, value });
        }
    }

    public async refresh(): Promise<void> {
        await this.loadDeviceInfo();

        this.tableContent = [];

        const initStatus: boolean|Error = await this.bluetoothService.initialize();
        this.initialized = initStatus === true;
        const initError: Error = initStatus as Error;
        if (initError && initError.message) {
            this.tableContent.push(new InfoRow(false, AppIcons.checkFailed, `${this.translateService.instant("BleStatus.systemMessageColon")} ${initError.message}`));
        }

        if (this.initialized) {
            this.tableContent.push(new InfoRow(true, AppIcons.checkSuccess, this.translateService.instant("BleStatus.successfullyInitialized")));
        }

        try {
            if (this.deviceInfo) {
                if (this.deviceInfo.manufacturer.startsWith("Google") && this.deviceInfo.webViewVersion) {
                    const webViewMajor: number = Number(this.deviceInfo.webViewVersion.split(".")[0]);
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    if (webViewMajor < 117) {
                        this.tableContent.push(new InfoRow(false, AppIcons.checkFailed, `${this.translateService.instant("System.chromeBrowserTooOld")}`));
                    }
                }

                if (this.deviceInfo.platform == "web" && this.deviceInfo.iOSVersion) {
                    this.tableContent.push(new InfoRow(false, AppIcons.checkFailed, `${this.translateService.instant("System.notSupportedOnIosBrowser")}`));
                }
            }
        } catch (error) {
            console.warn(error);
        }

        this.infoTable?.renderRows();
    }

    private bluetoothStatusUpdated(status: BluetoothStatus): void {
        this.bluetoothStatus = status;
    }
}
