import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { BaseComponent } from "../../../base/components/base/base-component";
import { QuickReportAppFormsApiService, QuickReportAppRegisterFormRequestDto, SubscriptionDto } from "../../../generated/api";
import { SubscriptionService } from "../../services/subscription/subscription.service";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { ErrorMessage } from "../error-dialog/error-message";

/**
 *
 */
@Component({
    selector: "app-register-form",
    templateUrl: "./register-form.component.html",
    styleUrls: ["./register-form.component.scss"]
})
export class RegisterFormComponent extends BaseComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly serialNumber: string,
        private readonly subscriptionService: SubscriptionService,
        private readonly formApiService: QuickReportAppFormsApiService,
        private readonly formBuilder: FormBuilder,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<RegisterFormComponent>
    ) {
        super();
        this.formData = this.formBuilder.group({
            firstName: [{ value: "", disabled: this.loading }, [Validators.required]],
            lastName: [{ value: "", disabled: this.loading }, [Validators.required]],
            email: [{ value: "", disabled: this.loading }, [Validators.required, Validators.email]],
            confirmEmail: [{ value: "", disabled: this.loading }, [Validators.required, Validators.email]],
            organization: [{ value: "", disabled: this.loading }, [Validators.required]],
            streetAddress: [{ value: "", disabled: this.loading }],
            city: [{ value: "", disabled: this.loading }],
            state: [{ value: "", disabled: this.loading }],
            postalCode: [{ value: "", disabled: this.loading }],
            country: [{ value: "", disabled: this.loading }],
            privacyPolicyAccepted: [false, Validators.requiredTrue]
        });
    }

    public formData: FormGroup;
    public submitted: boolean = false;
    public emailChecked: boolean = true;
    public errorMessage: string = "";
    public unableToCreateError: boolean = false;
    public allFieldsFilledError: boolean = false;

    protected loading: boolean = false;
    protected success: boolean = true;

    protected componentInit(): void {
        this.subscribe(this.formData.statusChanges, this.checkEmail);
    }

    protected componentDestroy(): void {
        // Do nothing for now.
    }

    public checkEmail(): void {
        if (this.formData) {
            const emailControl: FormControl<string|null>|null = this.formData.get("email") as FormControl<string|null>;
            const confirmEmailControl: FormControl<string|null>|null = this.formData.get("confirmEmail") as FormControl<string|null>;

            if (emailControl && confirmEmailControl) {
                const emailValue: string|null = emailControl.value;
                const confirmEmailValue: string|null = confirmEmailControl.value;
                if (emailValue !== null && confirmEmailValue !== null) {
                    this.emailChecked = emailValue == confirmEmailValue;
                } else {
                    console.error("Email or Confirm Email value is null.");
                }
            } else {
                console.error("Form control not found.");
            }
        } else {
            console.error("Form data not initialized.");
        }
    }

    public async submitForm(): Promise<void> {
        try {
            this.loading = true;
            this.errorMessage = "";
            this.unableToCreateError = false;
            this.allFieldsFilledError = false;

            if (!this.formData.get("privacyPolicyAccepted")?.value) {
                this.formData.get("privacyPolicyAccepted")?.markAsTouched();
                return;
            }

            if (!this.formData.valid) {
                this.allFieldsFilledError = true;
                return;
            }

            const dto: QuickReportAppRegisterFormRequestDto = {
                serialNumber: this.serialNumber,
                email: this.formData.get("email")?.value as string,
                companyName: this.formData.get("organization")?.value as string,
                firstName: this.formData.get("firstName")?.value as string,
                lastName: this.formData.get("lastName")?.value as string,
                city: this.formData.get("city")?.value as string,
                country: this.formData.get("country")?.value as string,
                state: this.formData.get("state")?.value as string,
                zip: this.formData.get("postalCode")?.value as string,
                street: this.formData.get("streetAddress")?.value as string
            };

            const response: SubscriptionDto = await lastValueFrom(this.formApiService.apiV1FormsQraRegisterPost(dto));
            this.success = !!response.identifier;
            if (this.success) {
                const fetchedLicense: SubscriptionDto|undefined = await this.subscriptionService.getFromServer(this.serialNumber, environment.deviceLicensePlanCode);
                this.success = fetchedLicense?.serialNumber == this.serialNumber;
            }
            if (!this.success) {
                this.unableToCreateError = true;
                return;
            }
            this.showSuccess();
        } catch (error: any) {
            if ("message" in error) {
                this.errorMessage = error.message as string;
                return;
            }
            this.errorMessage = error as string;
        } finally {
            this.loading = false;
        }
    }

    public openPrivacyPolicy(): void {
        window.open(environment.privacyPolicyUrl, "_blank");
    }

    public showSuccess(): void {
        this.dialogRef.close();
        this.dialog.open(ErrorDialogComponent, {
            data: {
                title: "Subscription.createdTitle",
                advice: ["Subscription.createdMessage"],
                details: [],
                severity: "info",
                buttons: [{ title: "LicensePage.title", uri: "license" }]
            } as ErrorMessage
        });
    }
}
