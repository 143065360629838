import { Component } from "@angular/core";
import { NavigationService } from "src/app/base/services/navigation/navigation.service";

import { BaseComponent } from "../../../base/components/base/base-component";
import { Part } from "../../datamodel/part";
import { Property } from "../../datamodel/property";
import { PartService } from "../../services/part/part-service";

/**
 *
 */
@Component({
    selector: "app-part-list",
    templateUrl: "./part-list.component.html",
    styleUrls: ["./part-list.component.scss"]
})
export class PartListComponent extends BaseComponent {

    constructor(
        private readonly partService: PartService,
        private readonly navigationService: NavigationService
    ) {
        super();

        this.caption = "PartList.title";
        partService.listParts().then((parts: Array<Part>) => {
            this.parts = parts;
        });
    }

    public parts: Array<Part> = [];
    protected readonly partClass = Part;
    public filteredParts: Array<Part> = [];
    protected searchQuery: string = "";

    protected componentInit(): void {
        this.initialize().then();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private async initialize(): Promise<void> {
        await this.updateParts();
    }

    private async updateParts(): Promise<void> {
        this.parts = await this.partService.listParts();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredParts = this.parts;
            return;
        }
        const filtered: Array<Part> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        for (const part of this.parts) {
            const partDescription: string|undefined = Property.findByNameKey(Part.partPropertyDescription, part.properties)?.value?.toLowerCase();
            if ((!Number.isNaN(queryAsNumber) && part.id == queryAsNumber) || partDescription?.includes(query)) {
                filtered.push(part);
            }
        }

        this.filteredParts = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public loadPart(part: Part): void {
        this.navigationService.navigateForward(`parts/${part.id}`).then();
    }
}
