import {Measurement} from "./measurement";

/**
 * Associates measurements with the corresponding measurement point.
 */
export class MeasuredPoint {
    public partId: number;
    public measurementPointId: string;
    public measurements: Array<Measurement> = [];

    public constructor(partId: number, pointId: string, initialMeasurements: Array<Measurement> = []) {
        this.partId = partId;
        this.measurementPointId = pointId;
        this.measurements = initialMeasurements;
    }
}
