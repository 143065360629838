import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { SubscriptionDto, SubscriptionsApiService } from "../../../generated/api";
import { db } from "../../db";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class SubscriptionService {
    constructor(
        private readonly subscriptionsApiService: SubscriptionsApiService
    ) {
    }

    public async saveDeviceSubscriptions(serialNumber: string, subscriptions: Array<SubscriptionDto>): Promise<void> {
        const keysToDelete: Array<number> = [];
        await db.deviceSubscriptions.each((dto: SubscriptionDto, cursor: { key: any; primaryKey: number }) => {
            if (dto.serialNumber == serialNumber) {
                keysToDelete.push(cursor.primaryKey);
            }
        });

        if (keysToDelete.length > 0) {
            await db.deviceSubscriptions.bulkDelete(keysToDelete);
        }
        await db.deviceSubscriptions.bulkPut(subscriptions);
    }

    public async findByDeviceSerial(serial: string): Promise<Array<SubscriptionDto>> {
        return db.deviceSubscriptions.filter((dto: SubscriptionDto) => !!dto.serialNumber && serial == dto.serialNumber).toArray();
    }

    public async getAllSubscriptions(): Promise<Array<SubscriptionDto>> {
        return db.deviceSubscriptions.filter((dto: SubscriptionDto) => !!dto.serialNumber).toArray();
    }

    public async getFromServer(serialNumber: string, planCode: string): Promise<SubscriptionDto|undefined> {
        const code: string = await CryptoHelper.hashParts(serialNumber, "all", environment.codeSeed);
        const subscriptions: Array<SubscriptionDto> = await lastValueFrom(this.subscriptionsApiService.apiV1SubscriptionsDevicesSerialNumberGet(serialNumber, "all", code));
        await this.saveDeviceSubscriptions(serialNumber, subscriptions);
        for (const subscription of subscriptions) {
            if (subscription.planCode == planCode) {
                return subscription;
            }
        }
        return undefined;
    }
}
