/**
 * Property is a DTO to describe a property of a job or a part.
 */
export class Property {
    public nameKey: string|undefined;
    public oldNameKey?: string;
    public nameEditable: boolean = false;
    public value: string|undefined;
    public deletable?: boolean;
    public deleted: boolean = false;
    public isNew: boolean = false;

    public static findByNameKey(nameKey: string, haystack: Array<Property>): Property|undefined {
        if (haystack) {
            for (const property of haystack) {
                if (property.nameKey == nameKey) {
                    return property;
                }
            }
        }
        return undefined;
    }
}
