import { Injectable } from "@angular/core";
import { DateTime } from "luxon";

import { CryptoHelper } from "../../../../base/helper/crypto-helper";
import { Image } from "../../../datamodel/image";
import { JobTemplate } from "../../../datamodel/job-template";
import { MeasurementPoint } from "../../../datamodel/measurement-point";
import { Part } from "../../../datamodel/part";
import { Property } from "../../../datamodel/property";
import { ImageService } from "../../../services/image/image-service";
import { JobTemplateService } from "../../../services/job-template/job-template.service";
import { PartService } from "../../../services/part/part-service";
import { SchemaMetaData } from "./schema-meta-data";
import { JobTemplateV1 } from "./v1/job-template.v1";
import { JobTemplateFileV1 } from "./v1/job-template-file.v1";
import { MeasurementPointV1 } from "./v1/measurement-point.v1";
import { PartV1 } from "./v1/part.v1";
import { PropertyV1 } from "./v1/property.v1";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateImporter {

    constructor(
        private jobTemplateService: JobTemplateService,
        private partService: PartService,
        private imageService: ImageService
    ) {}

    public async import(file: File): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const json: any = JSON.parse(new TextDecoder().decode(await file.arrayBuffer()));
        if (json.metaData) {
            const metaData: SchemaMetaData = json.metaData as SchemaMetaData;
            if (metaData.type == "jobTemplate") {
                if (metaData.version == "v1") {
                    return await this.importJobTemplateV1(json as JobTemplateFileV1);
                }
                throw new Error(`Unknown version: ${JSON.stringify(metaData.version)}`);
            } else {
                throw new Error(`Unknown type: ${JSON.stringify(metaData.type)}`);
            }
        } else {
            throw new Error(`Uploaded file does not contain the metaData property. Aborting import. Got: ${JSON.stringify(json)}`);
        }
    }

    private async importJobTemplateV1(jobTemplateFileV1: JobTemplateFileV1): Promise<boolean> {
        if (jobTemplateFileV1.jobTemplate) {
            const jobTemplateV1: JobTemplateV1 = jobTemplateFileV1.jobTemplate;
            const jobTemplate: JobTemplate = new JobTemplate();
            jobTemplate.correlationId = CryptoHelper.getUUID();
            jobTemplate.version = 1;
            jobTemplate.dateISO = DateTime.now().toISO()!;

            if (jobTemplateV1.parts) {
                jobTemplate.parts = await Promise.all(jobTemplateV1.parts.map(this.fromPartV1.bind(this)));
            }

            if (jobTemplateV1.properties) {
                jobTemplate.properties = jobTemplateV1.properties.map(this.fromPropertyV1);
            }

            await this.jobTemplateService.save(jobTemplate);
        } else {
            throw new Error("No job template in json.");
        }

        return true;
    }

    private async fromPartV1(partV1: PartV1): Promise<Part> {
        const part: Part = new Part();
        part.image = new Image();
        if (partV1.image) {
            if (partV1.image.binaryAsDataURL) {
                const binary: Blob = await (await fetch(partV1.image?.binaryAsDataURL)).blob();
                part.image.binaryId = await this.imageService.save(binary);
            }
            if (partV1.image.measurementPoints) {
                part.image.measurementPoints = partV1.image.measurementPoints.map(this.fromMeasurementPointV1);
            }
            part.image.imageWithMeasurementPointsAsDataURL = partV1.image.imageWithMeasurementPointsAsDataURL;
        }
        if (partV1.properties) {
            part.properties = partV1.properties.map(this.fromPropertyV1);
        }

        await this.partService.save(part);
        return part;
    }

    private fromMeasurementPointV1(measurementPointV1: MeasurementPointV1): MeasurementPoint {
        return new MeasurementPoint(measurementPointV1.order, measurementPointV1.x, measurementPointV1.y);
    }

    private fromPropertyV1(propertyV1: PropertyV1): Property {
        return {
            nameKey: propertyV1.name,
            nameEditable: propertyV1.nameEditable ?? true,
            value: propertyV1.value,
            deletable: propertyV1.deletable ?? true,
            deleted: false,
            isNew: false
        };
    }
}
