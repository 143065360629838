import { MeasurementPoint } from "./measurement-point";

/**
 * Image is a DTO to describe an image.
 */
export class Image {
    public measurementPoints: Array<MeasurementPoint> = [];
    public binaryId?: number;
    public imageWithMeasurementPointsAsDataURL?: string;
}
