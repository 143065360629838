<div class="dialog">
    <p mat-dialog-title>{{'JobTemplateSelector.title' | translate}}</p>

    <div mat-dialog-content>
        <mat-action-list>
            <button (click)="select(jobTemplate)" *ngFor="let jobTemplate of jobTemplates" mat-list-item>
                <fa-icon [icon]="appIcons.jobModeIcon" matListItemIcon></fa-icon>
                <span matListItemTitle>{{jobTemplate.id}} - {{jobTemplateClass.getJobTemplateDisplayName(jobTemplate)}}</span>
                <fa-icon [icon]="appIcons.clickableListItemIcon" matListItemMeta></fa-icon>
            </button>
        </mat-action-list>
        <p *ngIf="jobTemplates.length <= 0">{{'JobTemplateSelector.noTemplates' | translate}}</p>
    </div>

    <div mat-dialog-actions>
        <button (click)="cancel()" cdkFocusInitial color="primary" mat-flat-button>{{'Generic.cancel' | translate}}</button>
    </div>
</div>
