/**
 * Helper class to provide cryptography related functions.
 */
export class CryptoHelper {
    private static useUuidFallback: boolean = false;

    public static getUUID(): string {
        try {
            if (this.useUuidFallback || !location.protocol?.startsWith("https") || location.hostname?.startsWith("localhost")) {
                return this.getUUIDFallback();
            }

            const uuidMethod: () => string = (crypto as any)["randomUUID"] as () => string;
            let uuid: string|undefined;
            if (uuidMethod) {
                uuid = uuidMethod();
            }
            return uuid ? uuid : this.getUUIDFallback();
        } catch (error) {

            return this.getUUIDFallback();
        }
    }

    private static getUUIDFallback(): string {
        this.useUuidFallback = true;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return (`${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`).replace(/[018]/g, (c: string) =>
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            (parseInt(c, 10) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c, 10) / 4).toString(16)
        );
    }

    public static async hashParts(...parts: Array<string>): Promise<string> {
        const value: string = parts.join("-");
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return (await CryptoHelper.sha256(value)).substring(2, 12);
    }

    public static async sha256(input: string): Promise<string> {
        const encoder: TextEncoder = new TextEncoder();
        const data: Uint8Array = encoder.encode(input);

        const hashBuffer: ArrayBuffer = await crypto.subtle.digest("SHA-256", data);

        const hashArray: Array<number> = Array.from(new Uint8Array(hashBuffer));
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return hashArray.map((byte: number) => byte.toString(16).padStart(2, "0")).join("");
    }
}
