import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { BaseComponent } from "../../../base/components/base/base-component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { JobTemplateService } from "../../services/job-template/job-template.service";

/**
 * SelectJobTemplateComponent is used to select a JobTemplate from which a new job will be generated.
 */
@Component({
    selector: "app-select-job-template",
    templateUrl: "./select-job-template.component.html",
    styleUrls: ["./select-job-template.component.scss"]
})
export class SelectJobTemplateComponent extends BaseComponent {
    public constructor(
        private jobTemplateService: JobTemplateService,
        private dialogRef: MatDialogRef<SelectJobTemplateComponent>
    ) {
        super();
    }

    protected jobTemplates: Array<JobTemplate> = [];
    protected readonly jobTemplateClass: typeof Job = Job;

    protected componentInit(): void {
        this.initialize().then();
    }

    private async initialize(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public select(jobTemplate: JobTemplate): void {
        this.dialogRef.close(jobTemplate);
    }

    public cancel(): void {
        this.dialogRef.close();
    }
}
