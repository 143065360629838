<h1 mat-dialog-title>
    <fa-icon *ngIf="data.severity == 'error'" [icon]="appIcons.logsError" class="error"></fa-icon>
    <fa-icon *ngIf="data.severity == 'warn'" [icon]="appIcons.logsWarning" class="warning"></fa-icon>
    <fa-icon *ngIf="data.severity == 'info'" [icon]="appIcons.logsInfo"></fa-icon>
    {{data.title | translate}}
</h1>

<div class="content" mat-dialog-content>
    <p *ngFor="let advice of data.advice" class="advice">
        {{advice | translate}}
    </p>

    <span *ngIf="data.details.length > 0" class="headlineDetails">{{"ErrorDialog.details" | translate}}</span>
    <span *ngFor="let detail of data.details" class="details">
        {{detail}}
    </span>
</div>

<div class="actions" mat-dialog-actions>
    <ng-container *ngIf="data.buttons">
        <button (click)="navigate(button)" *ngFor="let button of data.buttons" [mat-dialog-close]="true" color="primary" mat-button>{{button.title | translate}}</button>
    </ng-container>
    <button [color]="getButtonColor(data.severity)" [mat-dialog-close]="true" mat-button>{{'Generic.ok' | translate}}</button>
</div>
