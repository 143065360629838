import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { UiHelper } from "../../helpers/ui-helper";

/**
 * Menubar for every page.
 */
@Component({
    selector: "app-menubar",
    templateUrl: "./menubar.component.html",
    styleUrls: ["./menubar.component.scss"]
})
export class MenubarComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly navigationService: NavigationService
    ) {
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
    }

    public bluetoothStatusEnum: typeof BluetoothStatus = BluetoothStatus;

    @Input()
    public header: string = "";
    @Input()
    public showBackButton: boolean = true;

    public bluetoothStatus: BluetoothStatus;
    protected readonly appIcons: typeof AppIcons = AppIcons;
    private connectionStatusSubscription?: Subscription;

    public get deviceConnected(): boolean {
        return this.bluetoothStatus == BluetoothStatus.connected;
    }

    public ngOnInit(): void {
        this.connectionStatusSubscription = this.bluetoothService.allDevicesStatusChanged.subscribe((next: BluetoothStatus) => {
            this.bluetoothStatus = next;
        });
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
    }

    public ngOnDestroy(): void {
        if (this.connectionStatusSubscription) {
            this.connectionStatusSubscription.unsubscribe();
        }
    }

    public openBleStatusPage(): Promise<boolean> {
        return this.navigationService.navigateForward("ble-status");
    }

    public statusToIcon(status: BluetoothStatus): IconDefinition {
        return UiHelper.getBluetoothStatusIcon(status);
    }
}
