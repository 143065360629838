<div class="content">
    <img class="logo" [ngSrc]="'assets/icons/quick-report-app-logo.png'" alt="Rhopoint Quick Report Logo" height="214" width="1158">
    <h1>Welcome to Quick Reports</h1>

    <div class="cards">
        <mat-card class="card">
            <div class="title">
                <div class="icon">
                    <fa-icon [icon]="appIcons.quickModeIcon"></fa-icon>
                </div>
                <span>Quick Mode</span>
            </div>

            <p>Quickly measure values from your device.</p>
        </mat-card>

        <mat-card class="card">
            <div class="title jobMode">
                <div class="icon">
                    <fa-icon [icon]="appIcons.jobModeIcon"></fa-icon>
                </div>
                <span>Job Mode</span>
            </div>
            <p>Quickly measure values from your device.</p>
        </mat-card>

        <mat-card class="card">
            <div class="title export">
                <div class="icon">
                    <fa-icon [icon]="appIcons.exportIcon"></fa-icon>
                </div>
                <span>Export</span>
            </div>
            <p>Export as PDF, CSV</p>
        </mat-card>
    </div>

    <hr />

    <button mat-flat-button color="primary" routerLink="/">Start</button>
</div>
