<ng-container [ngSwitch]="bluetoothStatus">
    <div *ngSwitchCase="bluetoothStatusEnum.disconnected">
        <p>{{'DeviceConnector.description' | translate}}</p>
        <mat-action-list>
            <button (click)="connect(device)" *ngFor="let device of supportedDevices" mat-list-item>
                <fa-icon [icon]="icons.bluetoothDeviceIcon" matListItemIcon></fa-icon>
                <span matListItemTitle>{{device}}</span>
            </button>
        </mat-action-list>
    </div>

    <div *ngSwitchCase="bluetoothStatusEnum.connected">
        <p>{{'DeviceConnector.askDisconnect' | translate}}</p>
        <button (click)="disconnect()" color="warn" mat-stroked-button>{{'DeviceConnector.disconnect' | translate}}</button>
    </div>

    <mat-action-list>
        <button (click)="openBleStatusPage()" mat-list-item>
            <fa-icon [icon]="icons.settingsIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{'BleStatus.title' | translate}}</span>
        </button>
    </mat-action-list>
</ng-container>

