import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Comment } from "./comment";

/**
 * Component to allow the user to type in comments.
 */
@Component({
    selector: "app-comments",
    templateUrl: "./comments.component.html",
    styleUrls: ["./comments.component.scss"]
})
export class CommentsComponent {

    constructor(public dialogRef: MatDialogRef<CommentsComponent>,
        @Inject(MAT_DIALOG_DATA) public comment: Comment) {
    }

    public confirmEdit(): void {
        this.comment.success = true;
    }
}
