<div class="container" *ngIf="image" [class]="mode"
     (mousedown)="allowEdit && mouseDown($event)"
     (mouseup)="allowEdit && mouseUp()"
     (touchend)="allowEdit && mouseUp()"
     (touchstart)="allowEdit && touchStart($event)"
     (mousemove)="allowEdit && mouseMoved($event)"
     (touchmove)="allowEdit && touchMove($event)">
    <div class="annotationArea" [style.aspect-ratio]="aspectRatio">
        <!--suppress AngularNgOptimizedImage -->
        <img #imageElement [src]="image" (load)="calculateAspectRatio()" alt="image" />

        <div class="canvas" #canvas>
            <div
                #movableMarkerElement
                class="marker movableMarker"
                [style.left]="movableMarker.x + '%'"
                [style.top]="movableMarker.y + '%'">
            </div>

            <div
                *ngFor="let marker of markers"
                class="marker"
                [class.selected]="selectedMarker?.order == marker.order"
                (mousedown)="allowEdit && startMovingMarker(marker, $event)"
                (touchstart)="allowEdit && startMovingMarker(marker, $event)"
                [style.left]="marker.x + '%'"
                [style.top]="marker.y + '%'">
                <span>{{marker.order}}</span>
            </div>
        </div>
    </div>
</div>
