import { AbstractEntity } from "./abstract-entity";
import { Part } from "./part";
import { Property } from "./property";

/**
 * A JobTemplate defines a job which can be executed using the job mode.
 */
export class JobTemplate extends AbstractEntity {
    public static readonly jobTemplatePropertyName: string = "Property.jobName";

    public parts: Array<Part> = [];
    public dateISO: string|undefined;

    public properties: Array<Property> = [{
        nameKey: JobTemplate.jobTemplatePropertyName,
        nameEditable: false,
        value: undefined,
        deletable: false,
        deleted: false,
        isNew: false
    }];

    public static getJobTemplateDisplayName(jobTemplate: JobTemplate): string {
        if (jobTemplate.properties) {
            for (const p of jobTemplate.properties) {
                if (p.nameKey == this.jobTemplatePropertyName && p.value && p.value.length > 0) {
                    return p.value;
                }
            }

            let sum: string = "";
            for (const p of jobTemplate.properties) {
                if (p.value) {
                    sum += `${p.value} `;
                }
            }
            return sum;
        }
        return "";
    }
}
