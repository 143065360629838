<h1 mat-dialog-title>{{'PropertyEditor.title' | translate}}</h1>
<div mat-dialog-content>
    <h3 *ngIf="!property.nameEditable && property.nameKey">{{property.nameKey | translate}}</h3>
    <mat-form-field *ngIf="property.nameEditable" appearance="outline">
        <mat-label>{{'PropertyEditor.propertyName' | translate}}</mat-label>
        <input [(ngModel)]="property.nameKey" matInput>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>{{'PropertyEditor.propertyValue' | translate}}</mat-label>
        <input [(ngModel)]="property.value" matInput>
    </mat-form-field>
    <div mat-dialog-actions>
        <button (click)="delete()" [disabled]="!property.deletable || property.isNew" [mat-dialog-close]="property" color="warn"
                mat-button>{{'Generic.delete' | translate}}
        </button>
        <button mat-button mat-dialog-close="true">{{'Generic.cancel' | translate}}</button>
        <button [mat-dialog-close]="property" color="primary" mat-button>{{'Generic.ok' | translate}}</button>
    </div>
</div>
