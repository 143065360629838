import { Component, Input } from "@angular/core";

import { DialogService } from "../../../business/services/dialog/dialog.service";
import { NavigationService } from "../../services/navigation/navigation.service";
import { BaseComponent } from "../base/base-component";

/**
 * BackButtonComponent keeps track of the last navigations and offers the option to navigate back.
 */
@Component({
    selector: "base-back-button",
    templateUrl: "./back-button.component.html",
    styleUrls: ["./back-button.component.scss"]
})
export class BackButtonComponent extends BaseComponent {
    constructor(
        private navigationService: NavigationService,
        private dialogService: DialogService
    ) {
        super();
    }

    @Input()
    public fallback: string = "/";

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public goBack(): void {
        this.navigationService.navigateBack(this.fallback).then();
    }
}
