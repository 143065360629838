import { Component, ElementRef, Input, ViewChild } from "@angular/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { readFileAsDataUrlAsync } from "../../../base/helper/file-reader-helper";
import { MeasurementPoint } from "../../datamodel/measurement-point";
import { Part } from "../../datamodel/part";
import { ImageService } from "../../services/image/image-service";

/**
 * Simple and compact UI view of a part.
 */
@Component({
    selector: "app-part-view",
    templateUrl: "./part-view.component.html",
    styleUrls: ["./part-view.component.scss"]
})
export class PartViewComponent extends BaseComponent {
    constructor(
        private imageService: ImageService) {
        super();
    }

    private partBacking: Part = new Part();
    public markers: Array<MeasurementPoint> = [];
    protected imageSource?: string;
    @ViewChild("imageSelect")
    private imageSelectRef?: ElementRef<HTMLInputElement>;
    private image?: File;

    @Input()
    public get part(): Part {
        return this.partBacking;
    }

    public set part(value: Part) {
        this.partBacking = value;
        this.initializeAndLoadImage().then();
    }

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public loadMarkers(): void {
        this.markers = [];

        if (this.part?.image?.measurementPoints) {
            this.markers = this.part.image.measurementPoints;
        }

        this.markers
            .filter((predicate: MeasurementPoint) => predicate.order != undefined)
            .sort((predicate: MeasurementPoint) => predicate.order!);
    }

    public async initializeAndLoadImage(): Promise<void> {
        if (this.part.image?.binaryId) {
            const blob: Blob|undefined = await this.imageService.load(this.part.image.binaryId);
            if (blob) {
                this.image = new File([blob], "image");
            }
        }
        await this.showImage();
        this.loadMarkers();
    }

    public async selectImage(): Promise<void> {
        if (this.imageSelectRef?.nativeElement) {
            this.imageSelectRef.nativeElement.onchange = async (event: Event): Promise<void> => {
                const files: FileList|null = (event.target as HTMLInputElement)?.files;

                if (files && files.length > 0) {
                    this.image = files[0];
                    await this.showImage();
                }
            };
            this.imageSelectRef.nativeElement.click();
        }
    }

    public async showImage(): Promise<void> {
        if (this.image) {
            this.imageSource = await readFileAsDataUrlAsync(this.image);
        }
    }
}
