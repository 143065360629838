import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { MeasurementPoint } from "../../../business/datamodel/measurement-point";

/**
 * This component displays all markers (measurement-points) on the given image.
 * Selecting/highlighting single markers is also supported.
 */
@Component({
    selector: "app-marker-overlay",
    templateUrl: "./marker-overlay.component.html",
    styleUrls: ["./marker-overlay.component.scss"]
})
export class MarkerOverlayComponent {
    @Input()
    public image?: string;

    @Input()
    public canSelectMarker: boolean = false;

    @Input()
    public markers: Array<MeasurementPoint> = [];

    @Input()
    public selectedMarker?: MeasurementPoint;

    @Input()
    public maxHeight?: string;

    @Output()
    public selectedMarkerChange: EventEmitter<MeasurementPoint> = new EventEmitter<MeasurementPoint>();

    @ViewChild("imageElement")
    private imageElementRef?: ElementRef<HTMLImageElement>;

    protected aspectRatio: number = 1;

    protected calculateAspectRatio(): void {
        if (!this.imageElementRef?.nativeElement) {
            return;
        }

        this.aspectRatio = this.imageElementRef.nativeElement.naturalWidth / this.imageElementRef.nativeElement.naturalHeight;
    }

    public selectMarker(marker: MeasurementPoint): void {
        if (this.canSelectMarker) {
            this.selectedMarkerChange.emit(marker);
        }
    }
}
