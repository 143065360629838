<app-menubar [showBackButton]="false" header="JobMode.title">
    <span class="toolbarSpacer"></span>
</app-menubar>

<div class="content">
    <button (click)="startNewJob()" class="iconButton bigButton" color="primary" mat-flat-button>
        <fa-icon [icon]="appIcons.genericAdd" matListItemIcon></fa-icon>
        <span>{{'JobMode.startJob' | translate}}</span>
    </button>
    <div>
        <mat-form-field *ngIf="jobs.length > 0" appearance="outline" class="search">
            <mat-label>{{'Generic.search' | translate}}</mat-label>
            <input (input)="applySearchFilter()" [(ngModel)]="searchQuery" matInput>
            <button (click)="emptyQuery()" *ngIf="searchQuery" aria-label="Clear" mat-button matSuffix>
                <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
            </button>
        </mat-form-field>
    </div>
    <p *ngIf="jobs.length > 0 && filteredJobs.length <= 0">{{'JobMode.notFound' | translate}} "{{searchQuery}}".</p>

    <mat-action-list>
        <button (click)="navigateToJob(job)" *ngFor="let job of filteredJobs" mat-list-item>
            <fa-icon [icon]="appIcons.jobModeIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{jobClass.getJobDisplayName(job)}}</span>
            <fa-icon [icon]="appIcons.clickableListItemIcon" matListItemMeta></fa-icon>
        </button>
    </mat-action-list>
</div>
