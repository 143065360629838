import { MatDialog } from "@angular/material/dialog";
import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import { BleService } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { DateTime } from "luxon";

import { ErrorDialogComponent } from "../../../../business/components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../../../business/components/error-dialog/error-message";
import { RegisterFormComponent } from "../../../../business/components/register-form/register-form.component";
import { Measurement } from "../../../../business/datamodel/measurement";
import { BackendService } from "../../../../business/services/backend/backend-service";
import { SettingsService } from "../../../../business/services/settings/settings-service";
import { DeviceSubscriptionStatus } from "../../../../business/services/subscription/device.subscription.status";
import { BluetoothStatus } from "../bluetooth-status";
import { BluetoothDevice } from "./bluetooth.device";

/**
 * This class implements creating a connection the device "Detail-O-Meter IQ" via bluetooth low energy and handles reading measurements from it.
 */
export class DetailometerIq extends BluetoothDevice {

    constructor(private backendService: BackendService) {
        super(DetailometerIq.deviceName);
    }

    public static readonly deviceName: string = "Detailometer";
    private readonly hiddenValues: Array<string> = [
        "Time",
        "Date",
        "Pass/Fail",
        "Calibrated",
        "Serial No.",
        "Certified",
        "Cdiode",
        "Haze C",
        "HAZE C",
        "GLOSS60",
        "GLOSS85",
        "ASTM D4039"
    ];

    private readonly measurementService: string = "49535343-fe7d-4ae5-8fa9-9fafd205e455";
    private measurementCharacteristic: string = "49535343-1E4D-4BD9-BA61-23C647249616";

    private readonly deviceInfoService: string = "ef9b2fb9-dc06-4432-8f46-f292abec4e93";
    private deviceInfoCharacteristic: string = "64767bab-ea07-4f78-9796-3b27cf565029";

    public requiredServices: Array<string> = [this.measurementService];
    public requiredAdvertisingServices: Array<string> = [this.deviceInfoService];
    private device?: BleDevice;

    public async connect(device: BleDevice, dialog: MatDialog, settingsService: SettingsService, onData: (measurement: Measurement) => void, onDisconnect: () => void): Promise<BluetoothStatus> {
        this.device = device;

        await BleClient.connect(this.device.deviceId, () => {
            this.device = undefined;
            onDisconnect();
        });

        if (settingsService.licenseCheckEnabled) {
            const serialNumber: string = await this.readSerialNumber();
            const subscriptionStatus: DeviceSubscriptionStatus = await this.backendService.hasValidLicense(serialNumber);
            if (subscriptionStatus != DeviceSubscriptionStatus.active) {
                onDisconnect();
                await this.disconnect();
                dialog.open(ErrorDialogComponent, {
                    data: {
                        title: "Subscription.missingSubscription",
                        advice: [subscriptionStatus],
                        details: [],
                        severity: "warn",
                        buttons: [{
                            title: "ErrorDialog.requestLicense",
                            component: RegisterFormComponent,
                            payload: serialNumber
                        }]
                    } as ErrorMessage
                });
                return BluetoothStatus.disconnected;
            }
        }

        await BleClient.startNotifications(this.device.deviceId, this.measurementService, this.measurementCharacteristic, (dataView: DataView) => {
            const decoder: TextDecoder = new TextDecoder();
            const data: string = decoder.decode(dataView);
            const lines: Array<string> = data.split("\n");
            console.info(lines);
            const measurement: Measurement = new Measurement();
            measurement.device = this.name;
            measurement.deviceId = this.device?.deviceId;
            measurement.timestamp = DateTime.now();
            measurement.values = [];
            let atLeastOneValidValue: boolean = false;
            for (const line of lines) {
                if (line.includes("=")) {
                    const splits: Array<string> = line.split("=");
                    if (splits.length == 2 && splits[0].trim().length > 0 && splits[1].trim().length > 0) {
                        const valueName: string = splits[0];
                        const value: string = splits[1].trim();
                        if (valueName == "Serial No.") {
                            measurement.serialNumber = value;
                        }
                        if (this.hiddenValues.includes(valueName)) {
                            continue;
                        }
                        atLeastOneValidValue = true;
                        measurement.values.push({
                            name: `Measurement.${valueName}`,
                            value: value
                        });
                    }
                }
            }
            if (atLeastOneValidValue) {
                onData(measurement);
            }
        });
        return BluetoothStatus.connected;
    }

    public async disconnect(): Promise<void> {
        if (this.device) {
            await BleClient.disconnect(this.device.deviceId);
        }
        this.device = undefined;
    }

    public async isConnected(): Promise<boolean> {
        try {
            if (this.device) {
                const services: Array<BleService> = await BleClient.getServices(this.device.deviceId);
                return services.length > 0;
            }
        } catch (error: any) {
            console.error(error);
        }
        return false;
    }

    private async readSerialNumber(): Promise<string> {
        if (this.device) {
            const deviceInfo: DataView = await BleClient.read(this.device.deviceId, this.deviceInfoService, this.deviceInfoCharacteristic);
            const deviceInfoAsString: string = new TextDecoder().decode(deviceInfo);

            const splits: Array<string> = deviceInfoAsString.split("-");
            if (splits.length > 2) {
                return splits[1];
            }
        }
        return "unknown device";
    }
}
