import { Component } from "@angular/core";

import { AppService } from "../../../business/services/app/app.service";
import { AppState } from "../../../business/services/app/app-state";
import { AppUpdateService } from "../../services/app-update/app-update.service";
import { MenuItem } from "../../services/navigation/menu-item";
import { NavigationService } from "../../services/navigation/navigation.service";
import { BaseComponent } from "../base/base-component";

/**
 * Shows a sidebar with all navigation entries.
 */
@Component({
    selector: "app-navigation-sidebar",
    templateUrl: "./navigation-sidebar.component.html",
    styleUrls: ["./navigation-sidebar.component.scss"]
})
export class NavigationSidebarComponent extends BaseComponent {
    public constructor(
        private readonly appService: AppService,
        private readonly appUpdateService: AppUpdateService,
        private readonly navigationService: NavigationService
    ) {
        super();

        this.appState = this.appService.appState;
        this.items = this.navigationService.menuItems;
    }

    public appState: AppState;
    public restartRequired: boolean = false;

    public items: Array<MenuItem>;

    public get activePath(): string {
        return this.navigationService.getActiveRoute;
    }

    protected componentInit(): void {
        this.subscribe(this.appUpdateService.updateAvailable, this.updateAvailable);
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public navigate(path: string): void {
        this.navigationService.setRoot(path).then();
    }

    public updateApp(): void {
        this.appService.restartApp().then();
    }

    public installApp(): void {
        this.appService.installPwa();
    }

    private updateAvailable(): void {
        this.restartRequired = true;
    }
}
