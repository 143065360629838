import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";

import { PropertyEditDialogComponent } from "../../business/components/property-edit-dialog/property-edit-dialog.component";
import { PropertyListComponent } from "../../business/components/property-list/property-list.component";
import { BackButtonComponent } from "./back-button/back-button.component";
import { MarkerAnnotationComponent } from "./marker-annotation/marker-annotation.component";
import { MarkerOverlayComponent } from "./marker-overlay/marker-overlay.component";
import { NavigationBarComponent } from "./navigation-bar/navigation-bar.component";
import { NavigationSidebarComponent } from "./navigation-sidebar/navigation-sidebar.component";

/**
 *
 */
@NgModule({
    declarations: [
        BackButtonComponent,
        MarkerAnnotationComponent,
        PropertyListComponent,
        PropertyEditDialogComponent,
        NavigationBarComponent,
        NavigationSidebarComponent,
        MarkerOverlayComponent
    ],
    exports: [
        BackButtonComponent,
        MarkerAnnotationComponent,
        PropertyListComponent,
        PropertyEditDialogComponent,
        NavigationBarComponent,
        NavigationSidebarComponent,
        MarkerOverlayComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        FormsModule,
        TranslateModule,
        MatToolbarModule,
        MatListModule,
        MatMenuModule
    ]
})
export class BaseComponentsModule {
}
