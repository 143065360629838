import { HttpErrorResponse } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";

import { AppException } from "../../entities/exceptions/app-exception";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class LoggingService {

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    private readonly maxMessages: number = 250;
    public messages: Array<any> = [];

    public unhandledTextMessageException: EventEmitter<string> = new EventEmitter<string>();

    public unhandledPromiseException: EventEmitter<Error> = new EventEmitter<Error>();

    public unhandledServerException: EventEmitter<AppException> = new EventEmitter<AppException>();

    public unhandledAppException: EventEmitter<AppException> = new EventEmitter<AppException>();

    private static prepareMessages(messages: Array<any>): Array<any> {
        const result: Array<string> = [];

        for (const message of messages) {
            if (!message) {
                result.push("null");
            } else if (message.name && message.message && message.stack) {
                result.push(`${message.name}: ${message.message}`);
                result.push(`${message.stack}`);
            } else if (message.message && message.stack) {
                result.push(message.message);
                result.push(`${message.stack}`);
            } else {
                switch (typeof message) {
                    case "string":
                    case "number":
                    case "boolean":
                        result.push(`${message}`);
                        break;
                    default:
                        try {
                            result.push(`${typeof message}: ${JSON.stringify(message)}`);
                        } catch (error) {
                            result.push(`${typeof message}: ${message}`);
                        }
                        break;
                }
            }
        }

        return result;
    }

    private appendLog(code: string, ...messages: Array<any>): void {
        const processed: Array<any> = LoggingService.prepareMessages(messages);
        this.messages.splice(0, 0, [new Date().toISOString(), code, processed]);
        this.messages.length = Math.min(this.messages.length, this.maxMessages);
    }

    public navigation(page: string, ...args: Array<any>): void {
        this.appendLog("Navigation", page, ...args);
    }

    private log(...messages: Array<any>): void {
        this.appendLog("Info", ...messages);
    }

    private info(...messages: Array<any>): void {
        this.appendLog("Info", ...messages);
    }

    private debug(...messages: Array<any>): void {
        this.appendLog("Debug", ...messages);
    }

    private warn(...messages: Array<any>): void {
        this.appendLog("Warning", ...messages);
    }

    private error(...messages: Array<any>): void {
        this.appendLog("Error", ...messages);

        if (messages && messages.length > 1 && (
            messages[1].message && messages[1].message.includes("Uncaught") && messages[1].message.includes("promise"))
            || messages[1] instanceof HttpErrorResponse
        ) {
            const exception: Error = messages[1] as Error;
            const innerException: Error = messages[1].rejection as Error;
            const appException: AppException = messages[1].rejection as AppException;
            if (innerException instanceof HttpErrorResponse) {
                this.unhandledServerException.emit(appException);
            } else if (appException) {
                this.unhandledAppException.emit(appException);
            } else if (innerException) {
                this.unhandledPromiseException.emit(innerException);
            } else if (exception) {
                this.unhandledPromiseException.emit(exception);
            } else {
                this.unhandledPromiseException.emit(messages[1]);
            }
        } else if (messages) {
            const stringMessages: string = this.messagesToString(messages);
            this.unhandledTextMessageException.emit(stringMessages);
        }
    }

    private messagesToString(messages: Array<any>): string {
        const builder: Array<string> = [];
        for (const message of messages) {
            if (typeof message == "object") {
                try {
                    builder.push(JSON.stringify(message));
                } catch (error) {
                    console.warn(error);
                }
            } else {
                builder.push(`${message}`);
            }
        }
        return builder.join("\n");
    }

    public intercept(): void {
        const interceptFunction: any = (method: string) => {
            // eslint-disable-next-line @typescript-eslint/ban-types
            const original: any = console[method as keyof Console] as Function;
            if (!original) {
                return;
            }

            const self: LoggingService = this;
            // @ts-expect-error: Invalid index type.
            console[method as keyof Console] = function(): void {
                try {
                    try {
                        // eslint-disable-next-line @typescript-eslint/ban-types
                        (self[method as keyof LoggingService] as Function).apply(self, arguments);
                    } catch (error) {
                        // Prevent circular calls
                    }

                    if (original.apply) {
                        original.apply(console, arguments);
                    } else {
                        const message: string = Array.prototype.slice.apply(arguments).join(" ") as string;
                        original(message);
                    }
                } catch (error) {
                    // Prevent circular calls
                }
            } as unknown;
        };
        ["log", "info", "debug", "warn", "error"].forEach((method: string) => {
            interceptFunction(method);
        });
    }
}
