<div class="annotationArea"
     [class.canSelectMarker]="canSelectMarker"
     [style.aspect-ratio]="aspectRatio"
     [style.max-height]="maxHeight">

    <!--suppress AngularNgOptimizedImage -->
    <img #imageElement [src]="image" (load)="calculateAspectRatio()" alt="image" />

    <div class="canvas">
        <div
            *ngFor="let marker of markers"
            class="marker"
            (click)="selectMarker(marker)"
            [class.selected]="selectedMarker?.order == marker.order"
            [style.left]="marker.x + '%'"
            [style.top]="marker.y + '%'">
            <span>{{marker.order}}</span>
        </div>
    </div>
</div>
