import { Component, Inject } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";

import { AppIcons } from "../../../app-icons";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { ButtonProperties } from "./button-properties";
import { ErrorMessage } from "./error-message";

/**
 * Component to show error information to the user.
 */
@Component({
    selector: "app-error-dialog",
    templateUrl: "./error-dialog.component.html",
    styleUrls: ["./error-dialog.component.scss"]
})
export class ErrorDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ErrorMessage,
        private readonly navigationService: NavigationService,
        private readonly dialog: MatDialog
    ) {
    }

    public readonly appIcons: typeof AppIcons = AppIcons;

    public async navigate(properties: ButtonProperties): Promise<boolean> {
        if (properties.uri) {
            if (properties.uri.startsWith("http")) {
                window.open(properties.uri, "_blank");
                return true;
            }
            return this.navigationService.navigateForward(properties.uri);
        }
        if (properties.component) {
            this.dialog.open(properties.component, {
                width: "100%",
                data: properties.payload
            });
            return true;
        }

        return false;
    }

    public getButtonColor(severity: string): ThemePalette {
        switch (severity) {
            case "info":
                return "primary";
            default:
                return "warn";
        }
    }
}
