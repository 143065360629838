import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { BaseComponent } from "../../../base/components/base/base-component";

/**
 * Shows a generic delete confirmation dialog.
 */
@Component({
    selector: "app-delete-dialog",
    templateUrl: "./delete-dialog.component.html",
    styleUrls: ["./delete-dialog.component.scss"]
})
export class DeleteDialogComponent extends BaseComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) private readonly data: any
    ) {
        super();
    }

    protected description: string = "";
    protected confirmPassword: string = "";
    protected confirmPasswordLabel: string = "";
    protected correctConfirmation: string = "ConfirmDelete";
    protected confirmation: string = "";

    protected componentInit(): void {
        this.caption = this.data.title as string;
        this.description = this.data.description as string;
        this.confirmPassword = this.data.confirmPassword as string;
        this.confirmPasswordLabel = this.data.confirmPasswordLabel as string;
    }

    protected componentDestroy(): void {
        // Do nothing for now.
    }
}
