import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

import { AppIcons } from "../../../app-icons";
import { readFileAsDataUrlAsync } from "../../../base/helper/file-reader-helper";
import { Photo } from "../../datamodel/photo";
import { ImageService } from "../../services/image/image-service";

/**
 *
 */
@Component({
    selector: "app-photo-list",
    templateUrl: "./photo-list.component.html",
    styleUrls: ["./photo-list.component.scss"]
})
export class PhotoListComponent {
    constructor(private imageService: ImageService) {}

    protected appIcons: typeof AppIcons = AppIcons;

    @Input()
    private photosBacking: Array<Photo> = [];
    @ViewChild("photoSelect")
    private imageSelectRef?: ElementRef<HTMLInputElement>;
    @Output()
    public newPhotoEvent: EventEmitter<File> = new EventEmitter<File>();
    @Output()
    public removePhotoEvent: EventEmitter<Photo> = new EventEmitter<Photo>();

    public photoBinaries: { [key: number]: string } = {};

    public get photos(): Array<Photo> {
        return this.photosBacking;
    }

    @Input()
    public set photos(photos: Array<Photo>) {
        this.photosBacking = photos;
        // this.photoBinaries = {};
        this.photosBacking.forEach((photo: Photo) => {
            if (photo.binaryId) {
                this.imageService.load(photo.binaryId).then((blob: Blob|undefined) => {
                    if (blob) {
                        readFileAsDataUrlAsync(new File([blob], `photo-${photo.binaryId}`)).then((dataUrl: string) => {
                            this.photoBinaries[photo.binaryId!] = dataUrl;
                        });
                    }
                });

            }
        });
    }

    public takePhoto(): void {
        if (this.imageSelectRef?.nativeElement) {
            this.imageSelectRef.nativeElement.onchange = async (event: Event): Promise<void> => {
                const files: FileList|null = (event.target as HTMLInputElement)?.files;
                if (files && files.length > 0) {
                    this.newPhotoEvent.emit(files[0]);
                }
            };
            this.imageSelectRef.nativeElement.click();
        }
    }

    public removePhoto(photo: Photo): void {
        this.removePhotoEvent.emit(photo);
    }
}
