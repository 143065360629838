<app-menubar [showBackButton]="true" header="BleStatus.title">
</app-menubar>

<div class="content">
    <div class="statusContainer">
        <fa-icon [icon]="statusIcon"></fa-icon>
        <span>{{statusText}}</span>
    </div>

    <table #infoTable [dataSource]="tableContent" mat-table>
        <ng-container matColumnDef="icon">
            <td *matCellDef="let row" mat-cell>
                <div [class]="{success: row.success, failed: !row.success}" class="iconContainer">
                    <fa-icon [icon]="row.icon"></fa-icon>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="text">
            <td *matCellDef="let row" mat-cell>
                {{row.text}}
            </td>
        </ng-container>

        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>

    <table #systemTable [dataSource]="systemTableContent" mat-table>
        <ng-container matColumnDef="key">
            <td *matCellDef="let row" mat-cell>
                {{row.key}}
            </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <td *matCellDef="let row" mat-cell>
                {{row.value}}
            </td>
        </ng-container>

        <tr *matRowDef="let row; columns: systemDisplayedColumns;" mat-row></tr>
    </table>

    <div class="helpText" *ngIf="!initialized">
        <div [innerHTML]="'BleStatus.helpText' | translate"></div>
</div>

