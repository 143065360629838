<app-menubar [header]="job ? jobClass.getJobDisplayName(job) : 'Job'" [showBackButton]="true">
    <button (click)="downloadPdf()" class="iconButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.downloadFile"></fa-icon>
        <span>{{'PdfExport.export' | translate}}</span>
    </button>
</app-menubar>

<div *ngIf="job" class="content">
    <app-property-list
        (propertiesUpdatedEvent)="updateProperties($event)"
        [allowNewProperties]="true"
        [properties]="job.properties">
    </app-property-list>

    <div *ngIf="job.parts" class="partsContainer">
        <button (click)="previousPart()" class="navigationButton" mat-fab>
            <fa-icon [icon]="appIcons.previousMarkerIcon"></fa-icon>
        </button>

        <swiper-container #swiperContainer effect="cards">
            <swiper-slide *ngFor="let part of job.parts; let index = index" class="slide">
                <app-part-editor (markerSelectedEvent)="markerChanged($event)" [caption]="getPartCaption(part)" [disabled]="false" [part]="part" mode="markerSelection"></app-part-editor>
            </swiper-slide>
        </swiper-container>

        <button (click)="nextPart()" class="navigationButton" mat-fab>
            <fa-icon [icon]="appIcons.nextMarkerIcon"></fa-icon>
        </button>
    </div>

    <div class="photos">
        <app-photo-list (newPhotoEvent)="onNewPhoto($event)" (removePhotoEvent)="onRemovePhoto($event)" [photos]="currentPhotos"></app-photo-list>
    </div>

    <div class="measurementsList">
        <app-measurement-table #measurementTable
                               (deleteMeasurementEvent)="this.bluetoothService.deleteMeasurement($event)"
                               (dirtyStateEvent)="this.save()"
                               [measurements]="currentMeasurements"
        >
        </app-measurement-table>
    </div>

    <button
        (click)="delete()"
        *ngIf="job.id"
        class="bigButton"
        color="warn"
        mat-flat-button>
        {{'Generic.delete' | translate}}
    </button>
</div>
