import { Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { db } from "../../db";
import { Personalization } from "./personalization";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class SettingsService {

    public licenseCheckEnabled: boolean = true;

    public async savePersonalization(personalization: Personalization): Promise<number> {
        return db.transaction("rw", db.personalization, async () => {
            let personalizationId: number;
            if (personalization.id) {
                await db.personalization.update(personalization.id, personalization);
                personalizationId = personalization.id;
            } else {
                personalizationId = await db.personalization.add(personalization);
            }
            return personalizationId;
        });
    }

    public async loadPersonalization(): Promise<Personalization> {
        const data: Array<Personalization> = await db.personalization.toArray();
        let personalization: Personalization = new Personalization();
        if (data.length > 0) {
            personalization = data[0];
        } else {
            personalization.version = 1;
            personalization.correlationId = CryptoHelper.getUUID();
        }
        return personalization;
    }
}
