<table *ngIf="highlightLastMeasurement && this.measurements.length > 0" class="lastMeasuredTable">
    <tr *ngFor="let measuredValue of getLastMeasurement().values">
        <td>
            {{"" + measuredValue.name | translate}}:
        </td>
        <td>
            {{uiHelper.format("" + measuredValue.value) | translate}}
            {{"" + (measuredValue.unit ? measuredValue.unit : "") | translate}}
        </td>
    </tr>
</table>

<div class="measurementsList">
    <table #measurementMatTable *ngIf="measurements.length > 0" [dataSource]="measurements" mat-table>
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell>{{column | translate}}</th>

            <td *matCellDef="let measurement" mat-cell>
                <fa-icon (click)="deleteMeasurement(measurement)" *ngIf="column == columnNameActions" [icon]="appIcons.trash" class="cellContent"></fa-icon>
                <span (click)="editComment(measurement)" *ngIf="column != columnNameActions" class="cellContent">
                    {{measurementTableComponent.getValueForColumn(column, measurement) | translate}} {{measurementTableComponent.getUnitForColumn(column, measurement) | translate}}
                </span>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: displayedColumns;" mat-row></tr>
    </table>

    <table #statisticsTable *ngIf="showStatistics && measurements.length > 0" [dataSource]="statistics" mat-table>
        <ng-container *ngFor="let column of statisticsColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell>{{column | translate}}</th>
            <td *matCellDef="let statistic" mat-cell> {{getStatisticsValue(statistic, column) | translate}}</td>
        </ng-container>

        <tr *matHeaderRowDef="statisticsColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: statisticsColumns;" mat-row></tr>
    </table>
</div>

<div *ngIf="measurements.length == 0">
    <mat-card>
        <mat-card-content>{{'QuickMode.noMeasurements' | translate}}</mat-card-content>
    </mat-card>
</div>

