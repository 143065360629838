/* eslint-disable max-classes-per-file */
import { Directive, EventEmitter, Input, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { EventHelper } from "../../helper/event-helper";

/**
 * Component base class with comfort functionality, especially for subscribing to events.
 */
@Directive()
export abstract class BaseComponent implements OnInit, OnDestroy {
    protected readonly snackbarDuration: number = 2000;
    protected readonly snackbarVerticalPosition: "top"|"bottom" = "top";
    protected appIcons: typeof AppIcons = AppIcons;

    @Input()
    public caption: string = "";

    protected destroySubject: Subject<void> = EventHelper.createDestroySubject();

    public ngOnInit(): void {
        this.componentInit();
    }

    public ngOnDestroy(): void {
        EventHelper.triggerDestroy(this.destroySubject);

        this.componentDestroy();
    }

    protected abstract componentInit(): void;

    protected abstract componentDestroy(): void;

    /**
     * Subscribe to an EventEmitter, Observable or Subject. It gets unsubscribed automatically after the component has been destroyed, so there is no cleanup necessary.
     * It does not matter who's the owner of the Observable/Event, you can subscribe to services, components, etc.
     * @param event - The event.
     * @param handler - The handler has to be a method within the component, because it gets bound to `this`.
     */
    protected subscribe<TEventData>(event: EventEmitter<TEventData>|Observable<TEventData>, handler: (eventData: TEventData) => void): void {
        EventHelper.subscribeUntilDestroyed(event, handler, this.destroySubject, this);
    }

    protected unsubscribe(subscription?: Subscription): void {
        EventHelper.unsubscribe(subscription);
    }
}
