import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { Part } from "../../datamodel/part";

/**
 * PartSelectorDialogComponent opens a dialog which allows to the user to select a part.
 */
@Component({
    selector: "app-part-selector-dialog",
    templateUrl: "./part-selector-dialog.component.html",
    styleUrls: ["./part-selector-dialog.component.scss"]
})
export class PartSelectorDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PartSelectorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { parts: Array<Part> }
    ) {

    }

    public selectedPart(part: Part): void {
        this.dialogRef.close(part);
    }
}
