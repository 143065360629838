<p mat-dialog-title>{{'PartSelector.title' | translate}}</p>

<div class="dialogContent" mat-dialog-content>
    <div *ngIf="selectableParts" class="container">
        <mat-selection-list (selectionChange)="selectionChanged($event)" [multiple]="false">
            <mat-list-option *ngFor="let part of selectableParts" [selected]="selectedPart == part" [value]="part">
                {{partClass.getDisplayName(part)}}
            </mat-list-option>
        </mat-selection-list>

        <div *ngIf="selectedPart" class="partPreview">
            <app-part-editor [part]="selectedPart" mode="readonly"></app-part-editor>
        </div>
    </div>
    <div *ngIf="!selectableParts || selectableParts.length == 0">
        <span>{{'PartSelector.noPartsAvailable' | translate}}</span>
    </div>
</div>

<div *ngIf="!selectableParts || selectableParts.length == 0" mat-dialog-actions>
    <button [matDialogClose]="false" color="primary" mat-flat-button>{{'Generic.cancel' | translate}}</button>
</div>

<div *ngIf="selectableParts && selectableParts.length > 0" mat-dialog-actions>
    <button (click)="save()" color="primary" mat-flat-button>{{'JobDesigner.selectPart' | translate}}</button>
</div>


