import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatSelectionListChange } from "@angular/material/list";

import { Part } from "../../datamodel/part";

/**
 * PartSelectorComponent shows the available parts out of which the user is able to select.
 */
@Component({
    selector: "app-part-selector",
    templateUrl: "./part-selector.component.html",
    styleUrls: ["./part-selector.component.scss"]
})
export class PartSelectorComponent {
    @Output("partSelectedEvent")
    public partSelectedEvent = new EventEmitter<Part>();
    private selectablePartsBacking?: Array<Part>;
    protected readonly partClass = Part;
    public selectedPart?: Part;
    public displayedColumns: Array<string> = ["name"];
    protected readonly close = close;

    public get selectableParts(): Array<Part>|undefined {
        return this.selectablePartsBacking;
    }

    @Input()
    public set selectableParts(value: Array<Part>) {
        this.selectablePartsBacking = value;
        if (this.selectablePartsBacking.length > 0) {
            this.selectedPart = this.selectablePartsBacking[0];
        }
    }

    public selectPart(part: Part): void {
        this.selectedPart = part;
    }

    public save(): void {
        this.partSelectedEvent.emit(this.selectedPart);
    }

    public selectionChanged(event: MatSelectionListChange): void {
        this.selectedPart = event.options[0].value as Part;
    }
}
