import { Component } from "@angular/core";

import { MenuItem } from "../../services/navigation/menu-item";
import { NavigationService } from "../../services/navigation/navigation.service";
import { BaseComponent } from "../base/base-component";

/**
 * Shows a tab-bar on the bottom with all navigation entries.
 */
@Component({
    selector: "app-navigation-bar",
    templateUrl: "./navigation-bar.component.html",
    styleUrls: ["./navigation-bar.component.scss"]
})
export class NavigationBarComponent extends BaseComponent {
    public constructor(
        private navigationService: NavigationService
    ) {
        super();
        this.items = this.navigationService.menuItems;
    }

    public items: Array<MenuItem>;

    public get activePath(): string {
        return this.navigationService.getActiveRoute;
    }

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public navigate(menuItem: MenuItem): void {
        if (menuItem.clickHandler) {
            menuItem.clickHandler();
            return;
        }
        this.navigationService.setRoot(menuItem.path).then();
    }
}
