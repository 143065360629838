/**
 * Reads a file and returns it as a data url.
 * @param file - The file to read from.
 * @returns A Promise containing the file as data url.
 */
export async function readFileAsDataUrlAsync(file: File): Promise<string> {
    return new Promise((resolve: (value: string) => void, reject: () => void) => {
        const reader: FileReader = new FileReader();

        reader.onload = (): void => {
            resolve(reader.result as string);
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

/**
 * Converts a data URL to a Blob.
 * @param blob - The blob to be converted.
 * @returns Data url as string.
 */
export async function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve: (value: (PromiseLike<string>|string)) => void, reject: (reason?: any) => void) => {
        const reader: FileReader = new FileReader();

        reader.onloadend = (): void => {
            if (typeof reader.result === "string") {
                resolve(reader.result);
            } else {
                reject(new Error("Failed to read data URL from blob."));
            }
        };

        reader.onerror = (): void => {
            reject(new Error("Error reading blob."));
        };

        reader.readAsDataURL(blob);
    });
}

