import { Component } from "@angular/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";

/**
 * View to prompt the user to activate this app with a license.
 */
@Component({
    selector: "app-onboarding-info",
    templateUrl: "./onboarding-info.component.html",
    styleUrls: ["./onboarding-info.component.scss"]
})
export class OnboardingInfoComponent extends BaseComponent {
    public constructor(
        private navigationService: NavigationService
    ) {
        super();
        navigationService.showMenu = false;
    }

    protected componentDestroy(): void {
        this.navigationService.showMenu = true;
    }

    protected componentInit(): void {
        // Empty
    }

    public async enterLicense(): Promise<void> {
        await this.navigationService.navigateForward("/license");
    }
}
