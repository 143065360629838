<h1 mat-dialog-title>{{caption | translate}}</h1>

<div mat-dialog-content>
    <p class="description">{{description | translate}}</p>
    <p class="confirm">{{confirmPassword | translate}}</p>

    <mat-form-field *ngIf="confirmPasswordLabel" class="confirmInput">
        <mat-label>{{confirmPasswordLabel | translate}}</mat-label>
        <input [(ngModel)]="confirmation" matInput>
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <button *ngIf="confirmation == correctConfirmation || !confirmPasswordLabel" [mat-dialog-close]="true" class="deleteButton" color="warn" mat-button>{{'Generic.delete' | translate}}</button>
    <button [mat-dialog-close]="false" mat-button>{{'Generic.cancel' | translate}}</button>
</div>
