import { Component, ViewChild } from "@angular/core";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { MeasurementTableComponent } from "../../components/measurement-table/measurement-table.component";
import { Measurement } from "../../datamodel/measurement";
import { QuickModePdf } from "../../services/pdf/documents/quickmode.pdf";

/**
 * QuickModeComponent allows the user to do measurements with a bluetooth device without having to create or select a JobTemplate.
 */
@Component({
    selector: "app-quick-mode",
    templateUrl: "./quick-mode.component.html",
    styleUrls: ["./quick-mode.component.scss"]
})
export class QuickModeComponent extends BaseComponent {

    constructor(public bluetoothService: BluetoothService, private translationService: TranslateService, private quickModePDF: QuickModePdf) {
        super();
        this.caption = "QuickMode.title";
    }

    public measurements: Array<Measurement> = [];
    @ViewChild("measurementTableComponent")
    private measurementTableComponent?: MeasurementTableComponent;
    private onDataSubscription?: Subscription;
    private onDeleteMeasurementSubscription?: Subscription;

    protected componentInit(): void {
        this.measurements = this.bluetoothService.measurements;
        this.onDataSubscription = this.bluetoothService.onData.subscribe(() => {
            this.measurementTableComponent?.refresh();
        });
        this.onDeleteMeasurementSubscription = this.bluetoothService.onDeleteMeasurement.subscribe((_measurement: Measurement) => {
            this.measurements = this.bluetoothService.measurements;
            this.measurementTableComponent?.refresh();
        });
    }

    protected componentDestroy(): void {
        this.onDataSubscription?.unsubscribe();
        this.onDeleteMeasurementSubscription?.unsubscribe();
    }

    public async downloadCsv(): Promise<void> {
        if (this.measurementTableComponent) {
            await this.measurementTableComponent.exportCsv();
        }
    }

    public async downloadPdf(): Promise<void> {
        await this.quickModePDF.generatePDF(this.measurements, async (base64Data: string) => {
            try {
                await FileSharer.share({
                    filename: "measurements.pdf",
                    contentType: "application/pdf",
                    base64Data: base64Data
                });
            } catch (error) {
                console.error(error);
            }
        });
    }

    public deleteAllMeasurements(): void {
        this.bluetoothService.measurements = [];
        this.measurements = this.bluetoothService.measurements;
    }
}
