import {CryptoHelper} from "../../base/helper/crypto-helper";

/**
 * DTO to define a measurement point.
 */
export class MeasurementPoint {
    public id: string;
    public order?: number;
    public x?: number;
    public y?: number;

    public constructor(order?: number, x?: number, y?: number) {
        this.id = CryptoHelper.getUUID();
        this.order = order;
        this.x = x;
        this.y = y;
    }
}
