import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

/**
 * BLE status info row.
 */
export class InfoRow {
    constructor(success: boolean, icon: IconDefinition, text: string) {
        this.success = success;
        this.icon = icon;
        this.text = text;
    }

    public success: boolean;
    public icon: IconDefinition;
    public text: string;
}
