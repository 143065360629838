import { Injectable } from "@angular/core";

import { blobToDataURL } from "../../../../base/helper/file-reader-helper";
import { JobTemplate } from "../../../datamodel/job-template";
import { MeasurementPoint } from "../../../datamodel/measurement-point";
import { Part } from "../../../datamodel/part";
import { Property } from "../../../datamodel/property";
import { ImageService } from "../../../services/image/image-service";
import { SchemaMetaData } from "./schema-meta-data";
import { ImageV1 } from "./v1/image.v1";
import { JobTemplateV1 } from "./v1/job-template.v1";
import { JobTemplateFileV1 } from "./v1/job-template-file.v1";
import { MeasurementPointV1 } from "./v1/measurement-point.v1";
import { PartV1 } from "./v1/part.v1";
import { PropertyV1 } from "./v1/property.v1";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateExporter {

    constructor(
        private imageService: ImageService
    ) {}

    public async exportJobTemplate(jobTemplate: JobTemplate): Promise<void> {
        const v1: JobTemplateFileV1 = new JobTemplateFileV1();
        v1.metaData = new SchemaMetaData();
        v1.metaData.version = "v1";
        v1.metaData.type = "jobTemplate";

        v1.jobTemplate = new JobTemplateV1();
        v1.jobTemplate.id = jobTemplate.id;
        v1.jobTemplate.correlationId = jobTemplate.correlationId;
        v1.jobTemplate.properties = jobTemplate.properties.map((property: Property) => this.asPropertyV1(property));
        v1.jobTemplate.parts = await Promise.all(jobTemplate.parts.map((part: Part) => this.asPartV1(part)));
        v1.jobTemplate.version = jobTemplate.version;

        const json: string = JSON.stringify(v1, undefined, 2);
        const a: HTMLAnchorElement = document.createElement("a");
        const blob: Blob = new Blob([json], { type: "text/plain" });
        const url: string = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = `job-template-${jobTemplate.id}.json`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    private asPropertyV1(p: Property): PropertyV1 {
        return {
            value: p.value,
            name: p.nameKey,
            nameEditable: p.nameEditable,
            deletable: p.deletable
        };
    }

    private async asPartV1(part: Part): Promise<PartV1> {
        const partV1: PartV1 = new PartV1();
        partV1.properties = part.properties.map((property: Property) => this.asPropertyV1(property));
        if (part.image) {
            partV1.image = new ImageV1();
            partV1.image.imageWithMeasurementPointsAsDataURL = part.image.imageWithMeasurementPointsAsDataURL;
            if (part.image.binaryId) {
                const image: Blob|undefined = await this.imageService.load(part.image.binaryId);
                if (image) {
                    partV1.image.binaryAsDataURL = await blobToDataURL(image);
                }
            }
            partV1.image.measurementPoints = part.image.measurementPoints.map((mp: MeasurementPoint) => this.toMeasurementPointV1(mp));
        }
        return partV1;
    }

    private toMeasurementPointV1(measurementPoint: MeasurementPoint): MeasurementPointV1 {
        return {
            order: measurementPoint.order,
            x: measurementPoint.x,
            y: measurementPoint.y
        };
    }
}
