import { Injectable } from "@angular/core";

import { db } from "../../db";
import { ImageStorageItem } from "./image-storage-item";

/**
 * ImageService stores and loads images in the indexeddb.
 */
@Injectable({
    providedIn: "root"
})
export class ImageService {

    public async save(blob: Blob): Promise<number> {
        return db.transaction("rw", db.images, async () => db.images.put({
            data: blob
        }));
    }

    public async load(id: number): Promise<Blob|undefined> {
        const item: ImageStorageItem|undefined = await db.transaction("r", db.images, async () => await db.images.get(id));
        return item ? item.data : undefined;
    }

    public async delete(id: number): Promise<void> {
        db.transaction("rw", db.images, async () => await db.images.delete(id));
    }
}
