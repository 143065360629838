/* eslint-disable @typescript-eslint/no-magic-numbers */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as pdfMake from "pdfmake/build/pdfmake";
import { Content, StyleDictionary, TDocumentDefinitions, TFontDictionary } from "pdfmake/interfaces";
import { firstValueFrom } from "rxjs";

import { environment } from "../../../../environments/environment";
import { PdfFonts } from "./pdfFonts";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class PdfGenerator {

    constructor(private http: HttpClient) {}

    public static readonly colorGreen: string = "#c39a51";
    public static readonly colorLightGrey: string = "#e9e9e9";
    public static readonly colorBlack: string = "#000000";
    public static readonly colorWhite: string = "#FFFFFF";
    public static readonly colorSandstone: string = "#f9f3e9";
    public static readonly colorSandstoneDark: string = "#ecdcc0";
    public static readonly marginLeft: number = 10;
    public static readonly marginRight: number = 10;
    public static readonly styles: StyleDictionary = {
        h1: {
            fontSize: 20,
            bold: true,
            noWrap: false,
            margin: [0, 0, 0, 10],
            font: "Disket"
        },
        h2: {
            fontSize: 16,
            bold: true,
            noWrap: false,
            margin: [0, 0, 0, 5],
            font: "Disket"
        },
        h3: {
            fontSize: 14,
            bold: false,
            noWrap: false,
            margin: [0, 0, 0, 5],
            font: "Mattone"
        },
        dataTableHeader: {
            bold: false,
            fontSize: 8,
            noWrap: false
        },
        dataTable: {
            margin: [0, 10, 0, 0],
            fontSize: 8,
            noWrap: false,
            font: "Mattone",
            bold: false
        },
        text8: {
            font: "Mattone",
            fontSize: 8
        },
        text10: {
            font: "Mattone",
            fontSize: 10
        },
        text12: {
            font: "Mattone",
            fontSize: 12
        },
        text14: {
            font: "Mattone",
            fontSize: 14
        }
    };

    public static readonly fontDictionary: TFontDictionary = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Disket: {
            bold: "Disket-Mono-Bold.ttf",
            normal: "Disket-Mono-Regular.ttf",
            italics: "Disket-Mono-Regular.ttf",
            bolditalics: "Disket-Mono-Bold.ttf"
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Roboto: {
            bold: "Roboto-Medium.ttf",
            italics: "Roboto-Italic.ttf",
            normal: "Roboto-Regular.ttf",
            bolditalics: "Roboto-MediumItalics.ttf"
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Mattone: {
            bold: "Mattone-Bold.ttf",
            normal: "Mattone-Regular.ttf",
            italics: "Mattone-Regular.ttf",
            bolditalics: "Mattone-Bold.ttf"
        }
    };

    public generate(documentDefinition: TDocumentDefinitions, callback: (base64Data: string) => void): void {
        documentDefinition.styles = PdfGenerator.styles;
        documentDefinition.footer = (currentPage: number, _pageCount: number): Content => {
            const content: Array<Content> = [];
            if (currentPage > 1) {
                content.push({
                    text: "www.detailometer.com",
                    style: "text8",
                    absolutePosition: {
                        x: 380,
                        y: 5
                    }
                });
                content.push({
                    image: environment.detailometerLogo,
                    width: 50,
                    absolutePosition: {
                        x: 500,
                        y: 0
                    }
                });

                content.push({
                    text: `${currentPage}`,
                    style: "text8",
                    absolutePosition: {
                        x: 40,
                        y: 0
                    }
                });
            }
            return content;
        };

        const pdf: pdfMake.TCreatedPdf = pdfMake.createPdf(documentDefinition, undefined, PdfGenerator.fontDictionary, PdfFonts.fontFiles);
        pdf.getBase64((base64Data: string) => {
            callback(base64Data);
        });
    }

    public async loadImage(image: string): Promise<Blob> {
        return await firstValueFrom(this.http.get(image, { responseType: "blob" }));

    }

}
