import { PartV1 } from "./part.v1";
import { PropertyV1 } from "./property.v1";

/**
 *
 */
export class JobTemplateV1 {

    public id: number|undefined;
    public correlationId: string|undefined;
    public version: number|undefined;
    public parts: Array<PartV1> = [];
    public date: Date|undefined;
    public properties: Array<PropertyV1> = [];

}
