import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { AppIcons } from "../../../app-icons";
import { AppService } from "../../../business/services/app/app.service";
import { AppState } from "../../../business/services/app/app-state";
import { EventHelper } from "../../helper/event-helper";
import { AppUpdateService } from "../app-update/app-update.service";
import { MenuItem } from "./menu-item";

/**
 * NavigationService is responsible for navigating through the application if needed.
 */
@Injectable({
    providedIn: "root"
})
export class NavigationService {
    constructor(
        private readonly appService: AppService,
        private readonly appUpdateService: AppUpdateService,
        private readonly router: Router
    ) {
        this.appState = this.appService.appState;
        this.menuItems.push(this.appUpdateMenuItem);
        this.menuItems.push(this.installAppMenuItem);

        EventHelper.subscribe(this.appUpdateService.updateAvailable, this.updateMenuItems, this);
        EventHelper.subscribe(this.appService.pwaCanBeInstalledUpdated, this.updateMenuItems, this);

        this.appUpdateMenuItem.clickHandler = this.appService.restartApp.bind(this.appService);
        this.installAppMenuItem.clickHandler = this.appService.installPwa.bind(this.appService);
        this.updateMenuItems();
    }

    public showMenu: boolean = true;

    public appState: AppState;

    private history: Array<string> = [];

    private appUpdateMenuItem: MenuItem = {
        icon: AppIcons.appUpdate,
        nameKey: "Generic.updateAvailable",
        path: "",
        visibility: "none",
        warn: true
    };

    private installAppMenuItem: MenuItem = {
        icon: AppIcons.installApp,
        nameKey: "Settings.installApp",
        path: "",
        visibility: "none"
    };

    public menuItems: Array<MenuItem> = [
        {
            icon: AppIcons.quickModeIcon,
            nameKey: "Generic.quickMode",
            path: "quick-mode",
            visibility: "both"
        },
        {
            icon: AppIcons.jobModeIcon,
            nameKey: "Generic.jobMode",
            path: "jobs",
            visibility: "both"
        },
        {
            icon: AppIcons.jobTemplateEditorIcon,
            nameKey: "Generic.jobDesigner",
            path: "job-templates",
            visibility: "both"
        },
        {
            icon: AppIcons.partsEditorIcon,
            nameKey: "Generic.partDesigner",
            path: "parts",
            visibility: "both"
        },
        {
            icon: AppIcons.settingsIcon,
            nameKey: "Generic.settings",
            path: "settings",
            visibility: "tabs"
        }
    ];

    public get currentUrlSegments(): Array<string> {
        const urls: Array<string> = window.location.pathname.split("/");
        urls.shift();

        return urls || [""];
    }

    public get getActiveRoute(): string {
        if (this.currentUrlSegments.length <= 0) {
            return "";
        }

        const activeRootRoute: string = this.currentUrlSegments[0];

        return activeRootRoute.toLowerCase();
    }

    public setRoot(path: string): Promise<boolean> {
        this.history = [];
        return this.navigateForward(path);
    }

    public navigateForward(path: string): Promise<boolean> {
        this.history.push(path);
        return this.router.navigateByUrl(path);
    }

    public navigateBack(fallbackPath: string = "/"): Promise<boolean> {
        let previousPath: string = fallbackPath;

        const currentUrl: Array<string> = this.currentUrlSegments;

        if (currentUrl.length > 1) {
            currentUrl.pop();

            previousPath = currentUrl.join("/");
        } else {
            if (this.history.length > 1) {
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                previousPath = this.history[this.history.length - 2];
            }
        }

        this.history.pop();
        return this.router.navigateByUrl(previousPath);
    }

    private updateMenuItems(): void {
        this.appUpdateMenuItem.visibility = this.appUpdateService.restartPending ? "tabs" : "none";
        this.installAppMenuItem.visibility = !this.appUpdateService.restartPending && !this.appState.pwaInstalled ? "tabs" : "none";
    }
}
