import { MeasurementPointV1 } from "./measurement-point.v1";

/**
 *
 */
export class ImageV1 {

    public measurementPoints: Array<MeasurementPointV1> = [];
    public binaryAsDataURL: string|undefined;
    public imageWithMeasurementPointsAsDataURL: string|undefined;
}
