import { Content } from "pdfmake/interfaces";

/**
 *
 */
export class Line {

    constructor(private height: number, private color: string) {

    }

    public render(): Content {
        return {
            table: {
                widths: ["*"],
                heights: this.height,
                body: [
                    [
                        {
                            text: "",
                            fillColor: this.color,
                            color: this.color
                        }
                    ]
                ]
            },
            layout: "noBorders"
        };
    }
}
