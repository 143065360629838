<mat-toolbar class="toolbar">
    <base-back-button *ngIf="showBackButton"></base-back-button>
    <span class="title">{{header | translate}}</span>

    <span class="toolbarSpacer"></span>

    <ng-content></ng-content>

    <ng-container [ngSwitch]="bluetoothStatus">
        <button *ngSwitchCase="bluetoothStatusEnum.unknown" color="primary" mat-stroked-button>
            <fa-icon [icon]="appIcons.deviceStatusCheck" class="align-right" />
        </button>
        <button (click)="openBleStatusPage()" *ngSwitchCase="bluetoothStatusEnum.unavailable" color="warn" mat-stroked-button>
            <fa-icon [icon]="appIcons.deviceUnavailable" class="align-right" />
        </button>
        <button *ngSwitchDefault [class.connected]="bluetoothStatus == bluetoothStatusEnum.connected" [matMenuTriggerFor]="bleMenu" color="primary" mat-stroked-button>
            <fa-icon [icon]="statusToIcon(bluetoothStatus)" class="align-right" />
        </button>
    </ng-container>

    <mat-menu #bleMenu>
        <div class="menuContent">
            <app-device-connector></app-device-connector>
        </div>
    </mat-menu>
</mat-toolbar>
