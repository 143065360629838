<mat-card>
    <mat-card-content>
        <div class="image">
            <app-marker-overlay
                *ngIf="imageSource"
                [image]="imageSource"
                maxHeight="100px"
                [markers]="markers">
            </app-marker-overlay>
        </div>
        <div class="meta">
            <div class="item" *ngFor="let property of part.properties">
                <span class="name" *ngIf="property.nameKey">{{property.nameKey | translate}}:&nbsp;</span>
                <span class="value" *ngIf="property.value">{{property.value}}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>
