import { Component } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { SelectJobTemplateComponent } from "../../components/select-job-template/select-job-template.component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { Property } from "../../datamodel/property";
import { JobService } from "../../services/job/job.service";

/**
 * Start page for the Job Mode. Displays a list with previous jobs.
 */
@Component({
    selector: "app-job-list",
    templateUrl: "./job-list.component.html",
    styleUrls: ["./job-list.component.scss"]
})
export class JobListComponent extends BaseComponent {
    constructor(
        private jobService: JobService,
        private dialog: MatDialog,
        private navigationService: NavigationService
    ) {
        super();
    }

    protected readonly jobClass: typeof Job = Job;
    protected jobs: Array<Job> = [];
    protected filteredJobs: Array<Job> = [];
    protected searchQuery: string = "";

    protected componentInit(): void {
        this.initialize().then();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    private async initialize(): Promise<void> {
        await this.updateJobs();
    }

    private async updateJobs(): Promise<void> {
        this.jobs = await this.jobService.listJobs();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredJobs = this.jobs;
            return;
        }

        const filtered: Array<Job> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        for (const job of this.jobs) {
            const clientName: string|undefined = Property.findByNameKey(Job.jobPropertyClient, job.properties)?.value?.toLowerCase();
            const registrationNumber: string|undefined = Property.findByNameKey(Job.jobPropertyNameRegistrationNumber, job.properties)?.value?.toLowerCase();
            if ((!Number.isNaN(queryAsNumber) && job.id == queryAsNumber) || clientName?.includes(query) || registrationNumber?.includes(query)) {
                filtered.push(job);
            }
        }

        this.filteredJobs = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public startNewJob(): void {
        const dialogRef: MatDialogRef<SelectJobTemplateComponent, JobTemplate> = this.dialog.open(SelectJobTemplateComponent, {
            maxWidth: "500px",
            maxHeight: "500px",
            width: "100%",
            height: "100%"
        });
        dialogRef.afterClosed().subscribe(async (jobTemplate: JobTemplate|undefined) => {
            if (jobTemplate) {
                const jobId: number = await this.jobService.createJob(jobTemplate);
                await this.navigationService.navigateForward(`jobs/${jobId}`);
            }
        });
    }

    public async navigateToJob(job: Job|JobTemplate): Promise<void> {
        await this.navigationService.navigateForward(`jobs/${job.id}`);
    }

}
