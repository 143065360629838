/**
 * Source code helper.
 */
export class TypescriptHelper {
    public static touch(ref: any): void {
        if (ref === undefined || ref === null) {
            console.warn("TypescriptHelper.touch got a undefined/null.");
        }
    }

    public static expectNever(argument: never): void {
        this.touch(argument);
    }
}
