<div class="navigationBar">
    <ng-container *ngFor="let menuItem of items">
        <button
            (click)="navigate(menuItem)"
            *ngIf="menuItem.visibility != 'sidebar' && menuItem.visibility != 'none'"
            [class.active]="menuItem.path == activePath"
            [class.warn]="menuItem.warn">
            <div class="buttonContent">
                <div class="iconContainer">
                    <fa-icon [icon]="menuItem.icon"></fa-icon>
                </div>

                <span>{{menuItem.nameKey | translate}}</span>
            </div>
        </button>
    </ng-container>
</div>
