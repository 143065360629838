import { faCircleCheck, faCrosshairs, faFileCertificate, faGear, faHourglassHalf, faImage, faList, faPenRuler, faPenToSquare, faRocket, faSignalStreamSlash, faSquareXmark, faStar } from "@fortawesome/pro-duotone-svg-icons";
import { faDisplayArrowDown } from "@fortawesome/pro-duotone-svg-icons/faDisplayArrowDown";
import { faTerminal, IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faAngleRight, faArrowRightFromBracket, faArrowRightToBracket, faArrowsRotate, faBars, faBluetooth, faBolt, faBug, faCircleInfo, faFileArrowDown, faFileExport, faGripLines, faHexagonExclamation, faInfoCircle, faLeft, faLink, faLinkSlash, faLocationArrow, faPlus, faRight, faTrashCan, faTriangleExclamation, faXmark, faXmarkLarge } from "@fortawesome/pro-solid-svg-icons";
import { faFileArrowUp } from "@fortawesome/pro-solid-svg-icons/faFileArrowUp";

/**
 * Icons used in the app.
 */
export class AppIcons {
    public static genericRefresh: IconDefinition = faArrowsRotate;
    public static menu: IconDefinition = faBars;
    public static star: IconDefinition = faStar;
    public static back: IconDefinition = faAngleLeft;
    public static expand: IconDefinition = faAngleRight;
    public static genericAdd: IconDefinition = faPlus;
    public static genericDelete: IconDefinition = faXmark;
    public static clickableListItemIcon: IconDefinition = faAngleRight;
    public static appUpdate: IconDefinition = faRocket;
    public static installApp: IconDefinition = faDisplayArrowDown;
    public static infoCircle: IconDefinition = faInfoCircle;
    public static genericDragUpDown: IconDefinition = faGripLines;

    public static debug: IconDefinition = faTerminal;
    public static exportIcon: IconDefinition = faFileExport;

    public static logsWarning: IconDefinition = faTriangleExclamation;
    public static logsDebug: IconDefinition = faBug;
    public static logsError: IconDefinition = faHexagonExclamation;
    public static logsInfo: IconDefinition = faCircleInfo;
    public static logsNavigation: IconDefinition = faLocationArrow;
    public static logsIntentStart: IconDefinition = faArrowRightFromBracket;
    public static logsIntentEnd: IconDefinition = faArrowRightToBracket;
    public static dialogClose: IconDefinition = faXmarkLarge;

    public static jobModeIcon: IconDefinition = faList;
    public static quickModeIcon: IconDefinition = faBolt;
    public static jobTemplateEditorIcon: IconDefinition = faPenToSquare;
    public static partsEditorIcon: IconDefinition = faPenRuler;
    public static settingsIcon: IconDefinition = faGear;

    public static changeImage: IconDefinition = faImage;
    public static markerIcon: IconDefinition = faCrosshairs;
    public static addPhoto: IconDefinition = faImage;

    public static deviceConnected: IconDefinition = faLink;
    public static deviceConnecting: IconDefinition = faHourglassHalf;
    public static deviceDisconnected: IconDefinition = faLinkSlash;
    public static deviceStatusCheck: IconDefinition = faHourglassHalf;
    public static deviceUnavailable: IconDefinition = faSignalStreamSlash;

    public static nextMarkerIcon: IconDefinition = faRight;
    public static previousMarkerIcon: IconDefinition = faLeft;

    public static bluetoothDeviceIcon: IconDefinition = faBluetooth;
    public static downloadFile: IconDefinition = faFileArrowDown;
    public static uploadFile: IconDefinition = faFileArrowUp;
    public static trash: IconDefinition = faTrashCan;

    public static checkSuccess: IconDefinition = faCircleCheck;
    public static checkFailed: IconDefinition = faSquareXmark;

    public static license: IconDefinition = faFileCertificate;
}
