import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { TranslateService } from "@ngx-translate/core";

import { AppIcons } from "../../app-icons";
import { TypescriptHelper } from "../../base/helper/typescript-helper";
import { BluetoothStatus } from "../../base/services/ble/bluetooth-status";

/**
 * Helper methods for the UI.
 */
export class UiHelper {
    public static translateService: TranslateService|undefined = undefined;

    public static getBluetoothStatusIcon(status: BluetoothStatus): IconDefinition {
        switch (status) {
            case BluetoothStatus.unknown:
                return AppIcons.deviceStatusCheck;
            case BluetoothStatus.disconnected:
                return AppIcons.deviceDisconnected;
            case BluetoothStatus.connected:
                return AppIcons.deviceConnected;
            case BluetoothStatus.unavailable:
                return AppIcons.deviceUnavailable;
            case BluetoothStatus.connecting:
                return AppIcons.deviceConnecting;
            default:
                TypescriptHelper.expectNever(status);
                return AppIcons.deviceUnavailable;
        }
    }

    public static getBluetoothStatusText(status: BluetoothStatus): string {
        switch (status) {
            case BluetoothStatus.unknown:
                return this.translateService?.instant("Bluetooth.statusInitializing");
            case BluetoothStatus.disconnected:
                return this.translateService?.instant("Bluetooth.statusDisconnected");
            case BluetoothStatus.connected:
                return this.translateService?.instant("Bluetooth.statusConnected");
            case BluetoothStatus.unavailable:
                return this.translateService?.instant("Bluetooth.statusUnavailable");
            case BluetoothStatus.connecting:
                return this.translateService?.instant("Bluetooth.statusConnecting");
            default:
                TypescriptHelper.expectNever(status);
                return this.translateService?.instant("Bluetooth.statusInitializing");
        }
    }

    public static format(value: string): string {
        const numberValue: number = Number(value);
        if (isNaN(numberValue)) {
            return value;
        } else {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return numberValue.toFixed(1);
        }
    }
}
