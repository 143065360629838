import { AbstractEntity } from "./abstract-entity";
import { Image } from "./image";
import { Property } from "./property";

/**
 * Part describes a physical thing with a set of measurement points along a set of properties describing the part.
 */
export class Part extends AbstractEntity {

    public static readonly partPropertyDescription: string = "Property.partDescription";

    public image: Image|undefined;

    public properties: Array<Property> = [{
        nameKey: Part.partPropertyDescription,
        nameEditable: false,
        value: undefined,
        deletable: false,
        deleted: false,
        isNew: false
    }];

    public static getDisplayName(part: Part): string {
        let namePropertyFound: boolean = false;
        if (part.properties) {
            for (const property of part.properties) {
                if (property.nameKey == Part.partPropertyDescription) {
                    namePropertyFound = true;
                    if (property.value) {
                        return property.value;
                    }
                }
            }
        }

        let displayName: string = "";
        if (!namePropertyFound) {
            for (const property of part.properties) {
                displayName += property.value ? `${property.value} ` : "";
            }
        }
        return displayName;
    }
}
