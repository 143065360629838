import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { DeleteDialogComponent } from "../../components/delete-dialog/delete-dialog.component";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class DialogService {
    constructor(
        public dialog: MatDialog
    ) {

    }

    public openDeleteDialog(title: string, description: string, confirmPassword?: string, confirmPasswordLabel?: string): Promise<boolean> {
        const dialog: MatDialogRef<DeleteDialogComponent> = this.dialog.open(DeleteDialogComponent, {
            data: {
                title: title,
                description: description,
                confirmPassword: confirmPassword,
                confirmPasswordLabel: confirmPasswordLabel
            }
        });

        return new Promise<boolean>((resolve: (value: (PromiseLike<boolean>|boolean)) => void) => {
            dialog.afterClosed().subscribe(async (result?: boolean) => {
                resolve(!!result);
            });
        });
    }
}
