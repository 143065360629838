import { CanDeactivateFn } from "@angular/router";

export interface ComponentCanDeactivate {
    willDeactivate: () => Promise<void>;
    canDeactivate: () => Promise<boolean>;
}

export const navigateAwayGuard: CanDeactivateFn<ComponentCanDeactivate> = async function(component: ComponentCanDeactivate) {
    await component.willDeactivate();
    return component.canDeactivate ? component.canDeactivate() : false;
};
