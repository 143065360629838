import { BluetoothStatus } from "./bluetooth-status";
import { BluetoothDevice } from "./devices/bluetooth.device";

/**
 * A device that is connected.
 */
export class BluetoothConnection {
    constructor(device: BluetoothDevice, status: BluetoothStatus = BluetoothStatus.unknown) {
        this.device = device;
        this.status = status;
    }

    public device: BluetoothDevice;
    public status: BluetoothStatus;
}
