/**
 * Static helper class for arrays.
 */
export class ArrayHelper {
    public static removeElement<TType>(array: Array<TType>, itemToRemove: TType): TType|undefined {
        const index: number = array.indexOf(itemToRemove);
        if (index < 0) {
            return undefined;
        }
        const removeElements: Array<TType> = array.splice(index, 1);
        return removeElements.length > 0 ? removeElements[0] : undefined;
    }
}
