import { CdkColumnDef } from "@angular/cdk/table";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatColumnDef, MatTable } from "@angular/material/table";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

import { AppIcons } from "../../../app-icons";
import { MeasuredValue } from "../../datamodel/measured-value";
import { Measurement } from "../../datamodel/measurement";
import { UiHelper } from "../../helpers/ui-helper";
import { DialogService } from "../../services/dialog/dialog.service";
import { Comment } from "../comments/comment";
import { CommentsComponent } from "../comments/comments.component";

/**
 * MeasurementTableComponent allows to display measurements of different formats in a table.
 */
@Component({
    selector: "app-measurement-table",
    templateUrl: "./measurement-table.component.html",
    styleUrls: ["./measurement-table.component.scss"],
    providers: [
        MatColumnDef,
        CdkColumnDef
    ]
})
export class MeasurementTableComponent implements OnInit {

    constructor(
        private dialog: MatDialog,
        private translationService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: DialogService) {
    }

    public static readonly commentColumnName: string = "Measurement.comment";
    public static readonly deviceColumnName: string = "Measurement.device";
    public static readonly deviceIdColumnName: string = "Measurement.deviceId";
    public static readonly timestampColumnName: string = "Measurement.timestamp";
    public static readonly measurementPointColumnName: string = "Measurement.measurementPoint";
    public static readonly statisticTypeColumn: string = "Statistics.type";
    @Input()
    public highlightLastMeasurement: boolean = false;
    @Input()
    public showStatistics: boolean = false;
    public displayedColumns: Array<string> = [];
    public statisticsColumns: Array<string> = [];
    public statistics: Array<any> = [];
    @Input()
    public isReadonly: boolean = false;
    public measurementTableComponent = MeasurementTableComponent;
    @ViewChild("measurementMatTable")
    private measurementMatTable?: MatTable<any>;
    @ViewChild("statisticsTable")
    private statisticsTable?: MatTable<any>;
    private measurementsBacking: Array<Measurement> = [];
    protected readonly uiHelper = UiHelper;
    protected readonly appIcons: typeof AppIcons = AppIcons;
    public readonly columnNameActions: string = "MeasurementTable.actions";

    @Output("deleteMeasurementEvent")
    public deleteMeasurementEvent: EventEmitter<Measurement> = new EventEmitter<Measurement>();
    @Output("dirtyStateEvent")
    public dirtyStateEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    public get measurements(): Array<Measurement> {
        return this.measurementsBacking;
    }

    @Input()
    public set measurements(measurements: Array<Measurement>) {
        this.displayedColumns = this.calcAllColumns();
        this.statisticsColumns = this.calculateStatisticsColumns();
        this.measurementsBacking = measurements;
        this.calculateStatistics();
        this.refresh();
    }

    public static getValueForColumn(columnName: string, measurement: Measurement): string {
        if (measurement) {
            for (const measuredValue of measurement.values) {
                if (measuredValue.name == columnName && measuredValue.value) {
                    return UiHelper.format(measuredValue.value);
                }
            }
        }
        if (columnName == MeasurementTableComponent.commentColumnName) {
            return measurement.comment ?? "---";
        }
        if (columnName == MeasurementTableComponent.deviceColumnName && measurement.device) {
            return measurement.device;
        }
        if (columnName == MeasurementTableComponent.deviceIdColumnName && measurement.deviceId) {
            return measurement.deviceId;
        }
        if (columnName == MeasurementTableComponent.timestampColumnName && measurement.timestamp) {
            return MeasurementTableComponent.toISO(measurement.timestamp);
        }
        return "";
    }

    public static getUnitForColumn(columnName: string, measurement: Measurement): string {
        if (measurement) {
            for (const measuredValue of measurement.values) {
                if (measuredValue.name == columnName && measuredValue.unit) {
                    return measuredValue.unit;
                }
            }
        }
        return "";
    }

    public static calculateColumns(measurements: Array<Measurement>): Array<string> {
        let cols: Array<string> = [];
        for (const measurement of measurements) {
            for (const measuredValue of measurement.values) {
                if (measuredValue.name && MeasuredValue.isValidValue(measuredValue)) {
                    if (!cols.includes(measuredValue.name)) {
                        cols.push(measuredValue.name);
                    }
                }
            }
        }
        cols = cols.sort();
        cols.push(MeasurementTableComponent.commentColumnName);

        return cols;
    }

    private static toISO(dateTime?: DateTime): string {
        if (dateTime) {
            const iso: string|null = dateTime.toISO();
            if (iso) {
                return iso;
            }
        }
        return "";
    }

    public getStatisticsValue(statistic: any, column: string): string {
        if (statistic[column]) {
            return statistic[column];
        } else {
            return "---";
        }
    }

    public ngOnInit(): void {
        this.refresh();
    }

    public getLastMeasurement(): Measurement {
        if (this.measurements.length > 0) {
            return this.measurements[this.measurements.length - 1];
        }
        return new Measurement();
    }

    public refresh(): void {
        this.displayedColumns = this.calcAllColumns();
        this.statisticsColumns = this.calculateStatisticsColumns();
        this.calculateStatistics();

        this.measurementMatTable?.renderRows();
        this.statisticsTable?.renderRows();
        this.changeDetectorRef.detectChanges();
    }

    private calcAllColumns(): Array<string> {
        const cols: Array<string> = [this.columnNameActions];
        cols.push(...MeasurementTableComponent.calculateColumns(this.measurements));
        return cols;
    }

    public editComment(measurement: Measurement): void {
        if (this.isReadonly) {
            return;
        }
        const dialogRef: MatDialogRef<CommentsComponent> = this.dialog.open(CommentsComponent, {
            data: {
                comment: measurement.comment
            }
        });

        dialogRef.afterClosed().subscribe((result?: Comment) => {
            if (result?.success) {
                measurement.comment = result?.comment;
                this.measurementMatTable?.renderRows();
                this.dirtyStateEvent.emit(true);
            }
        });
    }

    public async exportCsv(): Promise<void> {
        const csv: Array<string> = [];
        const csvColumns: Array<string> = [];
        csvColumns.push(MeasurementTableComponent.deviceColumnName);
        csvColumns.push(MeasurementTableComponent.deviceIdColumnName);
        csvColumns.push(MeasurementTableComponent.timestampColumnName);
        csvColumns.push(...this.displayedColumns);
        csv.push(csvColumns.map((value: string) => this.translationService.instant(value)).join(","));

        for (const measurement of this.measurements) {
            const row: Array<string> = [];
            for (const column of csvColumns) {
                row.push(MeasurementTableComponent.getValueForColumn(column, measurement));
            }
            csv.push(row.join(","));
        }

        const csvArray: string = csv.join("\r\n");
        const base64Data: string = btoa(csvArray);

        try {
            await FileSharer.share({
                filename: "measurements.csv",
                contentType: "text/csv",
                base64Data: base64Data
            });
        } catch (error) {
            console.error(error);
        }
    }

    private calculateStatisticsColumns(): Array<string> {
        const columns: Array<string> = [MeasurementTableComponent.statisticTypeColumn];
        columns.push(...this.displayedColumns.filter((c: string) => c != MeasurementTableComponent.commentColumnName && c != this.columnNameActions));
        return columns;
    }

    private calculateStatistics(): void {
        const max: any = {};
        max[MeasurementTableComponent.statisticTypeColumn] = "Statistics.max";

        const min: any = {};
        min[MeasurementTableComponent.statisticTypeColumn] = "Statistics.min";

        const avg: any = {};
        avg[MeasurementTableComponent.statisticTypeColumn] = "Statistics.avg";

        for (const statisticColumn of this.statisticsColumns) {
            if (statisticColumn == MeasurementTableComponent.statisticTypeColumn) {
                continue;
            }
            max[statisticColumn] = this.max(statisticColumn);
            min[statisticColumn] = this.min(statisticColumn);
            avg[statisticColumn] = this.avg(statisticColumn);
        }

        this.statistics = [];
        this.statistics.push(max);
        this.statistics.push(min);
        this.statistics.push(avg);
    }

    private max(column: string): string {
        let max: number = 0;
        for (const measurement of this.measurementsBacking) {
            const value: number = Number(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                max = value > max ? value : max;
            }
        }

        if (max > 0) {
            return UiHelper.format(`${max}`);
        } else {
            return "---";
        }
    }

    private min(column: string): string {
        let min: number = -1;
        for (const measurement of this.measurementsBacking) {
            const value: number = Number(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                min = (min < 0 || value < min) ? value : min;
            }
        }

        if (min < 0) {
            return "---";
        } else {
            return UiHelper.format(`${min}`);
        }
    }

    private avg(column: string): string {
        const values: Array<number> = [];
        for (const measurement of this.measurements) {
            const value: number = Number(MeasurementTableComponent.getValueForColumn(column, measurement));
            if (!isNaN(value)) {
                values.push(value);
            }
        }

        if (values.length > 0) {
            let sum: number = 0.0;
            values.forEach((v: number) => sum += v);
            return UiHelper.format(`${(sum / (values.length))}`);
        }
        return "---";
    }

    public async deleteMeasurement(measurement: Measurement): Promise<void> {
        const result: boolean = await this.dialogService.openDeleteDialog("DeleteDialog.title", "DeleteDialog.description");
        if (result) {
            this.deleteMeasurementEvent.emit(measurement);
        }
    }
}
