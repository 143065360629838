import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { RouterLink } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";

import { BaseComponentsModule } from "../../base/components/base-components.module";
import { CommentsComponent } from "./comments/comments.component";
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";
import { DemoTemplateInstallerComponent } from "./demo-template-installer/demo-template-installer.component";
import { DeviceConnectorComponent } from "./device-connector/device-connector.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { MeasurementTableComponent } from "./measurement-table/measurement-table.component";
import { MenubarComponent } from "./menubar/menubar.component";
import { OnboardingInfoComponent } from "./onboarding/onboarding-info.component";
import { PartEditorComponent } from "./part-editor/part-editor.component";
import { PartSelectorComponent } from "./part-selector/part-selector.component";
import { PartSelectorDialogComponent } from "./part-selector-dialog/part-selector-dialog.component";
import { PartViewComponent } from "./part-view/part-view.component";
import { PhotoListComponent } from "./photo-list/photo-list.component";
import { RegisterFormComponent } from "./register-form/register-form.component";
import { SelectJobTemplateComponent } from "./select-job-template/select-job-template.component";

/**
 * The module for the business components.
 */
@NgModule({
    declarations: [
        PartSelectorDialogComponent,
        PartSelectorComponent,
        PartEditorComponent,
        DeviceConnectorComponent,
        MeasurementTableComponent,
        MenubarComponent,
        PartViewComponent,
        CommentsComponent,
        SelectJobTemplateComponent,
        DeleteDialogComponent,
        PhotoListComponent,
        DemoTemplateInstallerComponent,
        OnboardingInfoComponent,
        ErrorDialogComponent,
        RegisterFormComponent
    ],
    exports: [
        PartSelectorDialogComponent,
        PartSelectorComponent,
        PartEditorComponent,
        DeviceConnectorComponent,
        MeasurementTableComponent,
        MenubarComponent,
        PartViewComponent,
        DeleteDialogComponent,
        PhotoListComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        MatButtonModule,
        MatTableModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        FormsModule,
        TranslateModule,
        BaseComponentsModule,
        MatListModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatMenuModule,
        NgOptimizedImage,
        MatProgressSpinnerModule,
        RouterLink,
        CdkTableModule,
        ReactiveFormsModule,
        MatCheckboxModule
    ]
})
export class BusinessComponentsModule {
}
