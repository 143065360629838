<h1 mat-dialog-title>{{'Comments.title' | translate}}</h1>
<div mat-dialog-content>
    <mat-form-field appearance="outline">
        <input [(ngModel)]="comment.comment" matInput>
    </mat-form-field>
    <div mat-dialog-actions>
        <button [mat-dialog-close]="true" mat-button>{{'Generic.cancel' | translate}}</button>
        <button (click)="confirmEdit()" [mat-dialog-close]="comment" color="primary" mat-button>{{'Generic.ok' | translate}}</button>
    </div>
</div>
